import React from 'react';
import {
  Box,
  Typography,
  Fade,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';

// Icons
import BusAlertIcon from '@mui/icons-material/BusAlert';
import LockIcon from '@mui/icons-material/Lock';
import Groups2Icon from '@mui/icons-material/Groups2';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LabelIcon from '@mui/icons-material/Label';

// Custom Components
import CustomCard from './CustomCard';

// Images
import WireFrameFuture from '../Photos/WireFrameFuture.png';

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const cardData = [
    {
      title: 'Bureau Portal',
      description: 'Welcome to the Bureau Monitoring Hub! Keep tabs on everything.',
      icon: <BusAlertIcon sx={{ fontSize: 40, color: '#fff' }} />,
      onClick: () => navigate('/bureau-dashboard'),
    },
    {
      title: 'TipLock',
      description: 'Dive into all things Tiplock! Manage and monitor activities with ease.',
      icon: <LockIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
    {
      title: 'Exco',
      description: 'Exclusively for Directors: get insights and stay ahead of the curve.',
      icon: <Groups2Icon sx={{ fontSize: 40, color: '#fff' }} />,
      onClick: () => navigate('/exco-dashboard'),
    },
    {
      title: 'Sales',
      description: 'Attention Sales Executives: monitor your clients’ Bureau activities.',
      icon: <AutoGraphIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
    {
      title: 'Insurance',
      description: 'For Insurer partners: manage and monitor risk profiles effortlessly.',
      icon: <LocalShippingIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
    {
      title: 'Whitelabel',
      description: 'Dedicated space for independent operations with optional support.',
      icon: <LabelIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
    {
      title: 'Projects',
      description: 'Coming Soon.',
      icon: <LabelIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
    {
      title: 'Admin',
      description: 'Coming Soon.',
      icon: <LabelIcon sx={{ fontSize: 40, color: '#fff' }} />,
    },
  ];

  return (
    <Box
      sx={{
        // Allow scrolling but ensure background fills the screen
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        overflow: 'auto',

        // Full background image
        backgroundImage: `url(${WireFrameFuture})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        // Stack hero and card sections
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Ensure it fills the entire screen height
      }}
    >
      {/* HERO SECTION */}
      <Box
        sx={{
          // Center horizontally
          display: 'flex',
          justifyContent: 'center',
          mt: { xs: 2, md: 12 },
          px: 2,
        }}
      >
        <Box
          // Translucent hero container
          sx={{
            maxWidth: 900,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#fff',
            borderRadius: 2,
            boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
            textAlign: 'center',
            p: { xs: 3, md: 6 },
          }}
        >
          <Typography
            variant={isSmallScreen ? 'h4' : 'h3'}
            fontWeight="bold"
            sx={{ fontFamily: 'Montserrat, sans-serif', mb: 2 }}
          >
            Welcome to the Admin Dashboard
          </Typography>

          <Typography
            variant={isSmallScreen ? 'body1' : 'h6'}
            sx={{ fontFamily: 'Roboto, sans-serif' }}
          >
            Manage and monitor all aspects of your operations from this central hub.
          </Typography>
        </Box>
      </Box>

      {/* CARDS SECTION */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: { xs: 3, md: 6 },
          px: 2,
        }}
      >
        <Grid2
          container
          spacing={{ xs: 3, md: 4 }}
          justifyContent="center"
          alignItems="stretch"
          sx={{ maxWidth: 1400 }}
        >
          {cardData.map((card, index) => (
            <Fade in={true} style={{ transitionDelay: `${index * 150}ms` }} key={card.title}>
              <Grid2
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                display="flex"
                justifyContent="center"
              >
                <CustomCard
                  title={card.title}
                  description={card.description}
                  icon={card.icon}
                  onClick={card.onClick}
                  sx={{
                    // Dark translucent style, similar to login modal
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: '#fff',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                    backdropFilter: 'blur(4px)',
                    borderRadius: 2,
                    width: 280, // Keep them at a smaller, consistent width
                    minHeight: 320,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                  }}
                />
              </Grid2>
            </Fade>
          ))}
        </Grid2>
      </Box>
    </Box>
  );
};

export default Dashboard;
