// src/Features/API/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for your Django backend
const BASE_URL = process.env.REACT_APP_API_URL;

// Function to get the stored authentication token
const getToken = () => sessionStorage.getItem('token'); // Retrieves the token from session storage

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      // Do not set 'Content-Type' here; let fetchBaseQuery handle it
      return headers;
    },
  }),
  tagTypes: [
    'DeviceGroup',
    'Vehicle',
    'Device',
    'Alert',
    'Event',
    'User',
    'UserGroup',
    'SalesReps',
    'Branches',
    'Technicians',
    'Test',
    'Client',
  ],
  endpoints: (builder) => ({
    fetchDeviceGroups: builder.query({

      query: () => 'device-groups/',
      providesTags: ['DeviceGroup'],
    }),

  fetchTests: builder.query({
    query: () => 'test-list/',
    providesTags: ['Test'],
  }),
    createUserGroup: builder.mutation({
      query: (groupData) => ({
        url: '/auth/groups/',
        method: 'POST',
        body: groupData,
        headers: { 'Content-Type': 'application/json' }, // Set 'Content-Type' here if needed
      }),
      invalidatesTags: ['UserGroup'],
    }),
    updateUserGroup: builder.mutation({
      query: ({ groupId, ...groupData }) => ({
        url: `/auth/groups/${groupId}/`,
        method: 'PUT',
        body: groupData,
      }),
      invalidatesTags: ['UserGroup'],
    }),
    fetchBranches: builder.query({
      query: () => 'branches/',
      providesTags: ['Branches'],  // or e.g. ['Branches'] if you plan to invalidate
    }),
  
    // 2) GET /technicians/  (optionally with ?branch_id=XY)
    fetchTechnicians: builder.query({
      // If you need an optional branchId param, do:
      query: (branchId) => {
        // If branchId is provided, pass ?branch_id=branchId
        const queryStr = branchId ? `?branch_id=${branchId}` : '';
        return `technicians/${queryStr}`;
      },
      providesTags: ['Technicians'],  // e.g. ['Technicians']
    }),
    deleteUserGroup: builder.mutation({
      query: (groupId) => ({
        url: `/auth/groups/${groupId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserGroup'],
    }),
    fetchUsers: builder.query({
      query: () => '/auth/users/',
      providesTags: ['User'],
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: '/auth/users/',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...userData }) => ({
        url: `/auth/users/${userId}/`,
        method: 'PUT',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/auth/users/${userId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    resetPassword: builder.mutation({
      query: ({ username, email, newPassword }) => ({
        url: '/auth/reset-password/',
        method: 'POST',
        body: { username, email, newPassword },
      }),
    }),
    fetchVehiclesForGroups: builder.query({
      query: () => 'vehicles/',
      providesTags: ['Vehicle'],
    }),
    fetchDevicesForVehicles: builder.query({
      query: () => 'devices/',
      providesTags: ['Device'],
    }),
    fetchAlerts: builder.query({
      query: () => 'alerts/',
      providesTags: ['Alert'],
    }),
    fetchAlertsByDevice: builder.query({
      query: (device_id) => `alerts/device/${device_id}/`,
      providesTags: ['Alert'],
    }),
    updateClient: builder.mutation({
      query: ({ group_id, ...data }) => ({
        url: `device-groups/${group_id}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    deleteClient: builder.mutation({
      query: (group_id) => ({
        url: `device-groups/${group_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    moveDeviceToGroup: builder.mutation({
      query: ({ device_id, new_group_id }) => ({
        url: `move-device/${device_id}/`,
        method: 'POST',
        body: { new_group_id },
      }),
      invalidatesTags: ['DeviceGroup', 'Device'],
    }),
    createEvent: builder.mutation({
      query: (formData) => ({
        url: '/events/',
        method: 'POST',
        body: formData,
        // Do not set headers here; fetchBaseQuery will handle 'Content-Type'
      }),
      invalidatesTags: ['Event'],
    }),
    fetchEvents: builder.query({
      query: () => 'events/list/',
      providesTags: ['Event'],
    }),
    fetchEventDetails: builder.query({
      query: (event_id) => `events/${event_id}/`,
      providesTags: ['Event'],
    }),
    createNewDevice: builder.mutation({
      query: (deviceData) => ({
        url: 'create-new-device/',
        method: 'POST',
        body: deviceData,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device'],
    }),
    fetchAndSaveDevicesFromGroup7842: builder.query({
      query: () => 'fetch-and-save-devices/',
      providesTags: ['Device'],
    }),
    createGroup: builder.mutation({
      query: (groupData) => ({
        url: 'create-group/',
        method: 'POST',
        body: groupData,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    createNewInstallTest: builder.mutation({
      query: (installTestData) => ({
        url: 'new-install-test/',
        method: 'POST',
        body: installTestData,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device', 'Vehicle', 'DeviceGroup'],
    }),
    assignDeviceToGroup: builder.mutation({
      query: (payload) => ({
        url: 'assign-device-to-group/',
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device'],
    }),
    updateVehicle: builder.mutation({
      query: (vehicleData) => ({
        url: 'update-vehicles/',
        method: 'POST',
        body: vehicleData,
      }),
      invalidatesTags: ['Vehicle', 'Device'],
    }),
    updateDevice: builder.mutation({
      query: (deviceData) => ({
        url: 'update-device/',
        method: 'POST',
        body: deviceData,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device', 'Vehicle'],
    }),
    createNewRepairTest: builder.mutation({
      query: (repairTestData) => ({
        url: 'new-repair-test/',
        method: 'POST',
        body: repairTestData, // { device: {...}, repair_test: {...} }
      }),
      invalidatesTags: ['Device'], // Or whatever you need
    }),
    createNewDeInstallTest: builder.mutation({
      query: ({ device_id, status, comments }) => ({
        url: 'new-deinstall-test/',
        method: 'POST',
        body: { device_id, status, comments }, // Flat payload as expected by backend
        headers: { 'Content-Type': 'application/json' }, // Ensure JSON content type
      }),
      invalidatesTags: ['Device', 'Vehicle'], // Adjust tags as needed
    }),
    fetchSalesReps: builder.query({
      query: () => 'sales-reps/',
      providesTags: ['SalesReps'],
    }),
    localAddDevices: builder.mutation({
      query: (devices) => ({
        url: 'create-device/',
        method: 'POST',
        body: devices,
      }),
      invalidatesTags: ['Device'],
    }),
    createClient: builder.mutation({
      query: (clientData) => ({
        url: 'create-client/',
        method: 'POST',
        body: clientData,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Client'],
    }),
    createDeviceSameGroup: builder.mutation({
      query: (payload) => ({
        url: 'create-device-same-group/',  // matches your new endpoint
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device'], // so the device list is refreshed if needed
    }),
    fetchClient: builder.query({
      query: (group_id) => `client-details/${group_id}/`,
      providesTags: ['Client'],
    }),
    associateDeviceWithVehicle: builder.mutation({
      query: (payload) => ({
        url: 'associate-device-with-vehicle/',
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Device', 'Vehicle'],
    }),
  }),
});

export const {
  useFetchDeviceGroupsQuery,
  useFetchVehiclesForGroupsQuery,
  useFetchDevicesForVehiclesQuery,  // New hook for fetching devices
  useFetchAlertsQuery,
  useFetchAlertsByDeviceQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useMoveDeviceToGroupMutation,  // New hook for moving devices
  useCreateEventMutation,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useFetchUsersQuery,
  useFetchEventsQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useResetPasswordMutation,
  useFetchEventDetailsQuery,
  useCreateNewDeviceMutation,
  useFetchAndSaveDevicesFromGroup7842Query,
  useCreateGroupMutation,
  useCreateNewInstallTestMutation,
  useAssignDeviceToGroupMutation,
  useUpdateVehicleMutation,
  useUpdateDeviceMutation,
  useCreateNewRepairTestMutation,
  useCreateNewDeInstallTestMutation,
  useFetchSalesRepsQuery,
  useLocalAddDevicesMutation,
  useCreateDeviceSameGroupMutation,
  useFetchBranchesQuery,
  useFetchTechniciansQuery,
  useFetchTestsQuery,
  useCreateClientMutation,
  useFetchClientQuery,
  useAssociateDeviceWithVehicleMutation,
} = apiSlice;