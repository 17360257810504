import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
  TextField,
  CircularProgress,
  Snackbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  ListItemText,
  Chip
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import {
  useCreateNewDeviceMutation,
  useCreateGroupMutation,
  useCreateClientMutation,
  useFetchDeviceGroupsQuery,
  useFetchSalesRepsQuery,
  useFetchDevicesForVehiclesQuery,
  useFetchBranchesQuery,
} from '../../Features/API/apiSlice';

const steps = [
  'Device Selection',
  'Group Information',
  'Registration Details Part 1',
  'Registration Details Part 2',
  'Needs Analysis',
  'Driver Details',
  'Summary and Finalization'
];

const AddClientWizard = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  // ============== NEW FIELDS ADDED TO formData ==============
  const [formData, setFormData] = useState({
    // Basic company details
    companyName: '',
    companyNumber: '',
    companyEmail: '',
    fleetCamPulse: false,

    // Sales rep info (ID + object, we store both to display but only send ID to the server)
    salesRepId: '',
    salesRep: null,

    // Group, Branch, and related info
    depotAddress: '',
    bureauPlan: [],
    branch: '',
    branchName: '',

    // Additional info
    customerCriticalAreas: '',
    notesAndSpecialRequirements: '',

    // NEW: extra "notes" field (distinct from notesAndSpecialRequirements)
    notes: '',

    // Device selection
    selectedDevices: [],
    drivers: [],

    // Contact details
    primaryName: '',
    primaryNumber: '',
    primaryEmail: '',
    primaryReceiveReports: false,
    primaryCallForAlerts: false,

    secondaryName: '',
    secondaryNumber: '',
    secondaryEmail: '',
    secondaryReceiveReports: false,
    secondaryCallForAlerts: false,

    emergencyName: '',
    emergencyNumber: '',
    emergencyEmail: '',
    emergencyReceiveReports: false,
    emergencyCallForAlerts: false,

    // Event Management (currently stored as string values "Yes"/"No")
    "Over Speeding": '',
    "Unauthorized Stops": '',
    "Cellphone Usage": '',
    "Smoking": '',
    "Passenger in-cab/ Illegal passenger collection": '',
    "Seatbelt Compliance": '',
    "All Stops on National Highways": '',
    "No Go Zone management and alerts": '',
    "Safe Zone Exit and Entry": '',

    // Needs Analysis fields
    speedViolationLimit: '',
    speedViolationDuration: '',
    operatingHours: '',
    parkingOutsideDepot: '',
    harshAccelerationBraking: '',
    excessiveIdling: '',

    // ========== NEW: "Call Drivers For..." booleans ==========
    callDriversForOverspeeding: false,
    callDriversForUnauthorizedStops: false,
    callDriversForPassengers: false,
    callDriversForCellphoneUsage: false,
    callDriversForSmoking: false,
    callDriversForSeatbelt: false,
    callDriversForCameraTampering: false,

    // ========== NEW: "Reports Required" booleans/frequencies ==========
    reportDetailedTrip: false,
    frequencyDetailedTrip: '',
    reportTripReportSummary: false,
    frequencyTripReportSummary: '',
    reportSpeedViolationReport: false,
    frequencySpeedViolationReport: '',
    reportEventReport: false,
    frequencyEventReport: '',
    reportTollReport: false,
    frequencyTollReport: '',
    reportZoneInOutReport: false,
    frequencyZoneInOutReport: '',
    reportDriverScorecardReport: false,
    frequencyDriverScorecardReport: '',
  });
  // ============================================================

  const [otherContacts, setOtherContacts] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [summaryPage, setSummaryPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  // =================== API QUERIES ===================
  const { data: groupsResponse, isLoading: groupsLoading } = useFetchDeviceGroupsQuery();
  const { data: salesRepsResponse, isLoading: salesRepsLoading, error: salesRepsError } = useFetchSalesRepsQuery();
  const { data: devicesResponse, isLoading: devicesLoading } = useFetchDevicesForVehiclesQuery();
  const { data: branchesResponse, isLoading: branchesLoading } = useFetchBranchesQuery();

  // =================== MUTATIONS =====================
  const [createNewDevice] = useCreateNewDeviceMutation();
  const [createGroup] = useCreateGroupMutation();
  const [createClient, { isLoading }] = useCreateClientMutation();

  // =================== DEVICE SELECTION (STEP 0) ===================
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const filteredDevices = devicesResponse?.devices.filter((device) =>
    device.imei?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleDeviceSelect = (deviceId) => {
    setFormData((prev) => ({
      ...prev,
      selectedDevices: prev.selectedDevices.includes(deviceId)
        ? prev.selectedDevices.filter((id) => id !== deviceId)
        : [...prev.selectedDevices, deviceId],
    }));
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // =================== NAVIGATION BETWEEN STEPS ===================
  const handleNext = async () => {
    if (activeStep === 0) {
      // Step 0: ensure devices are selected
      if (formData.selectedDevices.length === 0) {
        setSnackbar({ open: true, message: 'Please select at least one device', severity: 'error' });
        return;
      }
      setActiveStep(1);

    } else if (activeStep === 1) {
      // Step 1: create group
      try {
        const response = await createGroup({
          title: formData.companyName,
          open: true,
          devices: formData.selectedDevices
        }).unwrap();
        setSnackbar({ open: true, message: 'Group created successfully', severity: 'success' });
        setFormData((prevData) => ({
          ...prevData,
          group: response.group
        }));
        setActiveStep(2);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to create group', severity: 'error' });
      }

    } else if (activeStep === 2) {
      console.log('Registration Details Part 1:', formData);
      setActiveStep((prev) => prev + 1);

    } else if (activeStep === 3) {
      console.log('Registration Details Part 2:', formData);
      setActiveStep((prev) => prev + 1);

    } else if (activeStep === 4) {
      console.log('Needs Analysis:', formData);
      setActiveStep((prev) => prev + 1);

    } else if (activeStep === 5) {
      console.log('Driver Details:', formData);
      setActiveStep((prev) => prev + 1);

    } else if (activeStep === 6) {
      setOpenModal(true);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  // =================== ADD DRIVER / CONTACTS ===================
  const addDriver = () => {
    setFormData((prevData) => ({
      ...prevData,
      drivers: [...(prevData.drivers || []), { name: '', contactNumber: '', idNumber: '', vehicleRegistration: '' }]
    }));
  };
  const addOtherContact = () => {
    setOtherContacts([...otherContacts, { name: '', number: '', email: '', receiveReports: false, callForAlerts: false }]);
  };

  // =================== MODAL CLOSE ===================
  const handleModalClose = () => {
    setOpenModal(false);
  };

  // =================== FINAL SUBMISSION ===================
  const handleSubmit = async () => {
    // 1) Build "contacts" array
    const contactsPayload = [
      {
        contact_type: "Primary",
        name: formData.primaryName || "",
        number: formData.primaryNumber || "",
        email: formData.primaryEmail || "",
        receive_reports: formData.primaryReceiveReports || false,
        call_for_alerts: formData.primaryCallForAlerts || false,
      },
      {
        contact_type: "Secondary",
        name: formData.secondaryName || "",
        number: formData.secondaryNumber || "",
        email: formData.secondaryEmail || "",
        receive_reports: formData.secondaryReceiveReports || false,
        call_for_alerts: formData.secondaryCallForAlerts || false,
      },
      {
        contact_type: "Emergency",
        name: formData.emergencyName || "",
        number: formData.emergencyNumber || "",
        email: formData.emergencyEmail || "",
        receive_reports: formData.emergencyReceiveReports || false,
        call_for_alerts: formData.emergencyCallForAlerts || false,
      },
      ...otherContacts.map(contact => ({
        contact_type: "Other",
        name: contact.name || "",
        number: contact.number || "",
        email: contact.email || "",
        receive_reports: contact.receiveReports || false,
        call_for_alerts: contact.callForAlerts || false,
      }))
    ];

    // 2) Build "drivers" array
    const driversPayload = (formData.drivers || []).map((driver) => ({
      name: driver.name || "",
      contact_number: driver.contactNumber || "",
      id_number: driver.idNumber || "",
      vehicle_registration: driver.vehicleRegistration || "",
    }));

    // 3) Build "eventManagement" object
    const eventManagementPayload = {
      over_speeding: formData["Over Speeding"] === 'Yes',
      unauthorized_stops: formData["Unauthorized Stops"] === 'Yes',
      cellphone_usage: formData["Cellphone Usage"] === 'Yes',
      smoking: formData["Smoking"] === 'Yes',
      passenger_in_cab: formData["Passenger in-cab/ Illegal passenger collection"] === 'Yes',
      seatbelt_compliance: formData["Seatbelt Compliance"] === 'Yes',
      all_stops_on_national_highways: formData["All Stops on National Highways"] === 'Yes',
      no_go_zone_management: formData["No Go Zone management and alerts"] === 'Yes',
      safe_zone_exit_and_entry: formData["Safe Zone Exit and Entry"] === 'Yes',
    };

    // 4) Build "needsAnalysis" object
    const needsAnalysisPayload = {
      speed_violation_limit: formData.speedViolationLimit,
      speed_violation_duration: formData.speedViolationDuration,
      operating_hours: formData.operatingHours,
      parking_outside_depot: formData.parkingOutsideDepot,
      harsh_acceleration_braking: formData.harshAccelerationBraking === 'Yes',
      excessive_idling: formData.excessiveIdling === 'Yes',
    };

    // ================== 5) Construct final payload  ==================
    // Matches new single-Client-model fields in your backend
    const clientPayload = {
      group_id: formData.group?.group_id,
      company_name: formData.companyName,
      company_number: formData.companyNumber,
      company_email: formData.companyEmail,
      fleet_cam_pulse: formData.fleetCamPulse,
      bureau_plan: formData.bureauPlan,
      branch: formData.branch,
      depot_address: formData.depotAddress,
      customer_critical_areas: formData.customerCriticalAreas,
      notes_and_special_requirements: formData.notesAndSpecialRequirements,

      // Send only the salesRepId to the server
      sales_rep_id: formData.salesRepId || null,

      // Additional "notes" field
      notes: formData.notes || "",

      // "Call Drivers For..." booleans
      call_drivers_for_overspeeding: formData.callDriversForOverspeeding || false,
      call_drivers_for_unauthorized_stops: formData.callDriversForUnauthorizedStops || false,
      call_drivers_for_passengers: formData.callDriversForPassengers || false,
      call_drivers_for_cellphone_usage: formData.callDriversForCellphoneUsage || false,
      call_drivers_for_smoking: formData.callDriversForSmoking || false,
      call_drivers_for_seatbelt: formData.callDriversForSeatbelt || false,
      call_drivers_for_camera_tampering: formData.callDriversForCameraTampering || false,

      // "Reports Required" booleans + frequency
      report_detailed_trip: formData.reportDetailedTrip || false,
      report_detailed_trip_frequency: formData.frequencyDetailedTrip || "",
      report_trip_summary: formData.reportTripReportSummary || false,
      report_trip_summary_frequency: formData.frequencyTripReportSummary || "",
      report_speed_violation: formData.reportSpeedViolationReport || false,
      report_speed_violation_frequency: formData.frequencySpeedViolationReport || "",
      report_event: formData.reportEventReport || false,
      report_event_frequency: formData.frequencyEventReport || "",
      report_toll: formData.reportTollReport || false,
      report_toll_frequency: formData.frequencyTollReport || "",
      report_zone_in_out: formData.reportZoneInOutReport || false,
      report_zone_in_out_frequency: formData.frequencyZoneInOutReport || "",
      report_driver_scorecard: formData.reportDriverScorecardReport || false,
      report_driver_scorecard_frequency: formData.frequencyDriverScorecardReport || "",

      // Nested arrays/objects
      contacts: contactsPayload,
      drivers: driversPayload,
      event_management: eventManagementPayload,
      needs_analysis: needsAnalysisPayload,
    };

    // 6) Submit to backend
    try {
      await createClient(clientPayload).unwrap();
      setSnackbar({ open: true, message: 'Client saved successfully', severity: 'success' });
      if (onClose) onClose();
      setActiveStep(0);
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to save client:", error);
      setSnackbar({ open: true, message: 'Failed to save client', severity: 'error' });
    }
  };

  // =================== RETURN EARLY ON LOADING/ERROR ===================
  if (salesRepsLoading || branchesLoading) return <p>Loading...</p>;
  if (salesRepsError || groupsLoading) return <p>Error loading data</p>;

  // Build branch options
  const branchOptions = branchesResponse || [];

  // Updated Branch Dropdown
  const renderBranchDropdown = () => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="branch-label">Branch</InputLabel>
      <Select
    labelId="branch-label"
    value={formData.branch || ''}
    onChange={(e) => {
      // Convert the string to an integer
      const branchId = parseInt(e.target.value, 10);
      const branchObj = branchOptions.find((b) => b.id === branchId);
      setFormData({
        ...formData,
        branch: branchId,
        branchName: branchObj ? branchObj.name : '',
        salesRepId: '',
        salesRep: null,
      });
    }}
  >
    {branchOptions.map((branch) => (
      <MenuItem key={branch.id} value={branch.id}>
        {branch.name}
      </MenuItem>
    ))}
  </Select>
    </FormControl>
  );

  // Updated Sales Rep Dropdown: storing the rep ID & object
  const renderSalesRepDropdown = () => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Sales Rep</InputLabel>
      <Select
        value={formData.salesRepId || ''}
        onChange={(e) => {
          const selectedRepId = e.target.value;
          const selectedRep = (salesRepsResponse || []).find(rep => rep.id === selectedRepId);
          setFormData({
            ...formData,
            salesRepId: selectedRepId,
            salesRep: selectedRep
          });
        }}
        disabled={!formData.branch}
      >
        {(salesRepsResponse || [])
          .filter((rep) => {
            // Compare branch ID
            const repBranchId = rep.branch && typeof rep.branch === "object" ? rep.branch.id : rep.branch;
            return String(repBranchId) === String(formData.branch);
          })
          .map((rep) => (
            <MenuItem key={rep.id} value={rep.id}>
              {rep.first_name} {rep.last_name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
   // =================== QUICK EARLY RETURNS FOR LOADING/ERROR ===================
   if (salesRepsLoading || branchesLoading) return <p>Loading...</p>;
   if (salesRepsError || groupsLoading) return <p>Error loading data</p>;
 

  // =================== JSX STEPS RENDERING ===================
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={() => setActiveStep(steps.indexOf(label))}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, mb: 1, p: 2 }}>
        {/* STEP 0: Device Selection */}
        {activeStep === 0 && (
          <Box>
            <Typography variant="h6">Select Devices</Typography>
            <Button onClick={handleMenuOpen}>Select Devices</Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { maxHeight: 400, width: 300, overflow: 'auto' } }}
            >
              <TextField
                placeholder="Search Devices"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                sx={{ mb: 1, px: 2 }}
              />
              <Divider />
              {devicesLoading ? (
                <CircularProgress />
              ) : (
                <FixedSizeList
                  height={300}
                  width="100%"
                  itemSize={50}
                  itemCount={filteredDevices.length}
                >
                  {({ index, style }) => {
                    const device = filteredDevices[index];
                    return (
                      <MenuItem
                        key={device.device_id}
                        value={device.device_id}
                        style={style}
                        onClick={() => handleDeviceSelect(device.device_id)}
                      >
                        <Checkbox checked={formData.selectedDevices.includes(device.device_id)} />
                        <ListItemText primary={device.imei} />
                      </MenuItem>
                    );
                  }}
                </FixedSizeList>
              )}
            </Menu>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Selected Devices</Typography>
              {formData.selectedDevices && formData.selectedDevices.length > 0 ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {formData.selectedDevices.map((deviceId) => {
                    const device = devicesResponse?.devices?.find((d) => d.device_id === deviceId);
                    return (
                      <Chip key={deviceId} label={device ? device.imei : deviceId} color="primary" />
                    );
                  })}
                </Box>
              ) : (
                <Typography variant="body2">No devices selected yet.</Typography>
              )}
            </Box>
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mt: 2 }}>
              Next
            </Button>
          </Box>
        )}

        {/* STEP 1: Group Information */}
        {activeStep === 1 && (
          <Box>
            <TextField
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mb: 2 }}>
              Create Group
            </Button>
          </Box>
        )}

        {/* STEP 2: Registration Details Part 1 */}
        {activeStep === 2 && (
          <Box>
            <Typography variant="h6">Company Details</Typography>
            <TextField
              fullWidth
              label="Company Name"
              value={formData.companyName || ''}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Company Number"
              value={formData.companyNumber || ''}
              onChange={(e) => setFormData({ ...formData, companyNumber: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Company Email"
              value={formData.companyEmail || ''}
              onChange={(e) => setFormData({ ...formData, companyEmail: e.target.value })}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="bureau-plan-label">Bureau Plan</InputLabel>
              <Select
                labelId="bureau-plan-label"
                id="bureau-plan"
                multiple
                value={formData.bureauPlan || []}
                label="Bureau Plan"
                onChange={(e) => setFormData({ ...formData, bureauPlan: e.target.value })}
                renderValue={(selected) => selected.join(', ')}
              >
                {["AGILE", "SVR", "AD-HOC"].map((plan) => (
                  <MenuItem key={plan} value={plan}>
                    <Checkbox checked={formData.bureauPlan?.indexOf(plan) > -1} />
                    <ListItemText primary={plan} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Depot Address"
              value={formData.depotAddress || ''}
              onChange={(e) => setFormData({ ...formData, depotAddress: e.target.value })}
              sx={{ mb: 2 }}
            />
            
            {/* Render the Branch and Sales Rep dropdowns here */}
            {renderBranchDropdown()}
            {renderSalesRepDropdown()}

            <Typography variant="h6">Client Contact Details</Typography>
            {['Primary', 'Secondary', 'Emergency'].map((contactType, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">{contactType} Contact</Typography>
                <TextField
                  fullWidth
                  label="Name"
                  name={`${contactType.toLowerCase()}Name`}
                  value={formData[`${contactType.toLowerCase()}Name`] || ''}
                  onChange={(e) => setFormData({ ...formData, [`${contactType.toLowerCase()}Name`]: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Number"
                  name={`${contactType.toLowerCase()}Number`}
                  value={formData[`${contactType.toLowerCase()}Number`] || ''}
                  onChange={(e) => setFormData({ ...formData, [`${contactType.toLowerCase()}Number`]: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  name={`${contactType.toLowerCase()}Email`}
                  value={formData[`${contactType.toLowerCase()}Email`] || ''}
                  onChange={(e) => setFormData({ ...formData, [`${contactType.toLowerCase()}Email`]: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData[`${contactType.toLowerCase()}ReceiveReports`] || false}
                      onChange={(e) => setFormData({ ...formData, [`${contactType.toLowerCase()}ReceiveReports`]: e.target.checked })}
                    />
                  }
                  label="Receive Reports?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData[`${contactType.toLowerCase()}CallForAlerts`] || false}
                      onChange={(e) => setFormData({ ...formData, [`${contactType.toLowerCase()}CallForAlerts`]: e.target.checked })}
                    />
                  }
                  label="Call for Alerts"
                />
              </Box>
            ))}

            {otherContacts.map((contact, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Other Contact {index + 1}</Typography>
                <TextField
                  fullWidth
                  label="Name"
                  value={contact.name}
                  onChange={(e) => {
                    const updatedContacts = [...otherContacts];
                    updatedContacts[index].name = e.target.value;
                    setOtherContacts(updatedContacts);
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Number"
                  value={contact.number}
                  onChange={(e) => {
                    const updatedContacts = [...otherContacts];
                    updatedContacts[index].number = e.target.value;
                    setOtherContacts(updatedContacts);
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  value={contact.email}
                  onChange={(e) => {
                    const updatedContacts = [...otherContacts];
                    updatedContacts[index].email = e.target.value;
                    setOtherContacts(updatedContacts);
                  }}
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contact.receiveReports}
                      onChange={(e) => {
                        const updatedContacts = [...otherContacts];
                        updatedContacts[index].receiveReports = e.target.checked;
                        setOtherContacts(updatedContacts);
                      }}
                    />
                  }
                  label="Receive Reports?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contact.callForAlerts}
                      onChange={(e) => {
                        const updatedContacts = [...otherContacts];
                        updatedContacts[index].callForAlerts = e.target.checked;
                        setOtherContacts(updatedContacts);
                      }}
                    />
                  }
                  label="Call for Alerts"
                />
              </Box>
            ))}

            <Button variant="outlined" color="primary" onClick={addOtherContact} sx={{ mb: 2 }}>
              Add Other Contact
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mb: 2 }}>
              Next
            </Button>
          </Box>
        )}

        {/* STEP 3: Registration Details Part 2 */}
        {activeStep === 3 && (
          <Box>
            <Typography variant="h6">Event Management and Driver Coaching</Typography>
            {[
              'Over Speeding',
              'Unauthorized Stops',
              'Cellphone Usage',
              'Smoking',
              'Passenger in-cab/ Illegal passenger collection',
              'Seatbelt Compliance',
              'All Stops on National Highways',
              'No Go Zone management and alerts',
              'Safe Zone Exit and Entry'
            ].map((event, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>{event}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData[event] === 'Yes'}
                      onChange={() => setFormData({ ...formData, [event]: 'Yes' })}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData[event] === 'No'}
                      onChange={() => setFormData({ ...formData, [event]: 'No' })}
                    />
                  }
                  label="No"
                />
              </Box>
            ))}
            <TextField
              fullWidth
              label="Customer Critical Areas of Concern"
              value={formData.customerCriticalAreas || ''}
              onChange={(e) => setFormData({ ...formData, customerCriticalAreas: e.target.value })}
              multiline
              minRows={4}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Notes & Special Requirements"
              value={formData.notesAndSpecialRequirements || ''}
              onChange={(e) => setFormData({ ...formData, notesAndSpecialRequirements: e.target.value })}
              multiline
              minRows={4}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mb: 2 }}>
              Next
            </Button>
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mb: 2 }}>
              Back
            </Button>
          </Box>
        )}

        {/* STEP 4: Needs Analysis */}
        {activeStep === 4 && (
          <Box>
            <Typography variant="h6">Needs Analysis</Typography>
            <Typography variant="subtitle1">Utilization Rules</Typography>
            {[
              { label: 'Speed Violation Limit', name: 'speedViolationLimit' },
              { label: 'Speed Violation Duration before alert', name: 'speedViolationDuration' },
              { label: 'Operating hours', name: 'operatingHours' },
              { label: 'Parking Outside Depot After Hours', name: 'parkingOutsideDepot' },
              { label: 'Harsh Acceleration/Braking', unit: 'Yes/No', name: 'harshAccelerationBraking' },
              { label: 'Excessive Idling', unit: 'Yes/No', name: 'excessiveIdling' }
            ].map((rule, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  {rule.label}
                </Typography>
                {rule.name === 'speedViolationLimit' ? (
                  <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel>Km/h</InputLabel>
                    <Select
                      value={formData.speedViolationLimit || ''}
                      label="Km/h"
                      onChange={(e) =>
                        setFormData({ ...formData, speedViolationLimit: e.target.value })
                      }
                    >
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={80}>80</MenuItem>
                      <MenuItem value={90}>90</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={110}>110</MenuItem>
                      <MenuItem value={120}>120</MenuItem>
                    </Select>
                  </FormControl>
                ) : rule.name === 'speedViolationDuration' ? (
                  <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel>Seconds</InputLabel>
                    <Select
                      value={formData.speedViolationDuration || ''}
                      label="Seconds"
                      onChange={(e) =>
                        setFormData({ ...formData, speedViolationDuration: e.target.value })
                      }
                    >
                      {Array.from({ length: 12 }, (_, i) => (i + 1) * 10).map((sec) => (
                        <MenuItem key={sec} value={sec}>
                          {sec}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : rule.name === 'operatingHours' ? (
                  <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel>Hours</InputLabel>
                    <Select
                      value={formData.operatingHours || ''}
                      label="Hours"
                      onChange={(e) =>
                        setFormData({ ...formData, operatingHours: e.target.value })
                      }
                    >
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                    </Select>
                  </FormControl>
                ) : rule.name === 'parkingOutsideDepot' ? (
                  <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel>Hours</InputLabel>
                    <Select
                      value={formData.parkingOutsideDepot || ''}
                      label="Hours"
                      onChange={(e) =>
                        setFormData({ ...formData, parkingOutsideDepot: e.target.value })
                      }
                    >
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                    </Select>
                  </FormControl>
                ) : rule.unit === 'Yes/No' ? (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData[rule.name] === 'Yes'}
                          onChange={() =>
                            setFormData({ ...formData, [rule.name]: 'Yes' })
                          }
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData[rule.name] === 'No'}
                          onChange={() =>
                            setFormData({ ...formData, [rule.name]: 'No' })
                          }
                        />
                      }
                      label="No"
                    />
                  </>
                ) : null}
              </Box>
            ))}
            <Typography variant="subtitle1" sx={{ mt: 4 }}>Call Drivers For</Typography>
            {[
              'Overspeeding',
              'Unauthorized Stops',
              'Passengers in Vehicles',
              'Cellphone Usage',
              'Smoking',
              'Seatbelt Compliance',
              'Camera Tampering/Covering'
            ].map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={formData[`callDriversFor${item.replace(/\s+/g, '')}`] || false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [`callDriversFor${item.replace(/\s+/g, '')}`]: e.target.checked
                      })
                    }
                  />
                }
                label={item}
              />
            ))}
            <Typography variant="subtitle1" sx={{ mt: 4 }}>Reports Required</Typography>
            {[
              'Detailed Trip Report',
              'Trip Report Summary',
              'Speed Violation Report',
              'Event Report',
              'Toll Report',
              'Zone in/out Report',
              'Driver Scorecard Report'
            ].map((report, index) => {
              // Convert name to form keys:
              const keyEnabled = `report${report.replace(/\s+/g, '')}`;
              const keyFrequency = `frequency${report.replace(/\s+/g, '')}`;
              return (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>{report}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData[keyEnabled] || false}
                        onChange={(e) =>
                          setFormData({ ...formData, [keyEnabled]: e.target.checked })
                        }
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!formData[keyEnabled]}
                        onChange={(e) =>
                          setFormData({ ...formData, [keyEnabled]: !e.target.checked })
                        }
                      />
                    }
                    label="No"
                  />
                  <Select
                    value={formData[keyFrequency] || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, [keyFrequency]: e.target.value })
                    }
                    displayEmpty
                    sx={{ ml: 2, minWidth: 120 }}
                  >
                    <MenuItem value="" disabled>Frequency</MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </Select>
                </Box>
              );
            })}
            <TextField
              fullWidth
              label="Notes"
              value={formData.notes || ''}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              multiline
              minRows={4}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mb: 2 }}>
              Next
            </Button>
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mb: 2 }}>
              Back
            </Button>
          </Box>
        )}

        {/* STEP 5: Driver Details */}
        {activeStep === 5 && (
          <Box>
            <Typography variant="h6">Driver Details</Typography>
            {formData.drivers && formData.drivers.map((driver, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Driver Name and Surname"
                  value={driver.name}
                  onChange={(e) => {
                    const updatedDrivers = [...formData.drivers];
                    updatedDrivers[index].name = e.target.value;
                    setFormData({ ...formData, drivers: updatedDrivers });
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Driver Contact Number"
                  value={driver.contactNumber}
                  onChange={(e) => {
                    const updatedDrivers = [...formData.drivers];
                    updatedDrivers[index].contactNumber = e.target.value;
                    setFormData({ ...formData, drivers: updatedDrivers });
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Driver ID Number"
                  value={driver.idNumber}
                  onChange={(e) => {
                    const updatedDrivers = [...formData.drivers];
                    updatedDrivers[index].idNumber = e.target.value;
                    setFormData({ ...formData, drivers: updatedDrivers });
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Registration of Allocated Vehicle"
                  value={driver.vehicleRegistration}
                  onChange={(e) => {
                    const updatedDrivers = [...formData.drivers];
                    updatedDrivers[index].vehicleRegistration = e.target.value;
                    setFormData({ ...formData, drivers: updatedDrivers });
                  }}
                  sx={{ mb: 1 }}
                />
              </Box>
            ))}
            <Button variant="contained" color="primary" onClick={addDriver} sx={{ mb: 2 }}>
              Add Driver
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mb: 2 }}>
              Next
            </Button>
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mb: 2 }}>
              Back
            </Button>
          </Box>
        )}

        {/* STEP 6: Summary and Finalization */}
        {activeStep === 6 && (
          <Box>
            <Typography variant="h6">Summary and Finalization</Typography>
            {summaryPage === 0 && (
              <Box>
                <Typography variant="subtitle1">Select Devices</Typography>
                {formData.selectedDevices && formData.selectedDevices.length > 0 ? (
                  formData.selectedDevices.map((deviceId, index) => {
                    const device = devicesResponse?.devices?.find((d) => d.device_id === deviceId);
                    return (
                      <Typography key={index} variant="body2">
                        Device IMEI: {device ? device.imei : deviceId}
                      </Typography>
                    );
                  })
                ) : (
                  <Typography variant="body2">No devices selected.</Typography>
                )}
              </Box>
            )}
            {summaryPage === 1 && (
              <Box>
                <Typography variant="subtitle1">Create Group</Typography>
                <Typography variant="body2">
                  Company Name: {formData.companyName}
                </Typography>
                {formData.group && formData.group.group_id && (
                  <Typography variant="body2">
                    Group ID: {formData.group.group_id}
                  </Typography>
                )}
              </Box>
            )}
            {summaryPage === 2 && (
              <Box>
                <Typography variant="subtitle1">Company Details</Typography>
                <Typography variant="body2">
                  Contact Number: {formData.companyNumber}
                </Typography>
                <Typography variant="body2">
                  Company Email: {formData.companyEmail}
                </Typography>
                <Typography variant="body2">
                  FleetCam Pulse: {formData.fleetCamPulse ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2">
                  Depot Address: {formData.depotAddress}
                </Typography>
                <Typography variant="body2">
                  Branch: {formData.branchName || '-'}
                </Typography>
                {formData.salesRep && (
                  <Typography variant="body2">
                    Sales Rep: {formData.salesRep.first_name} {formData.salesRep.last_name}
                  </Typography>
                )}
                {formData.bureauPlan && formData.bureauPlan.length > 0 && (
                  <Typography variant="body2">
                    Bureau Plan: {formData.bureauPlan.join(', ')}
                  </Typography>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Client Contact Details</Typography>
                  {['Primary', 'Secondary', 'Emergency'].map((contactType) => (
                    <Box key={contactType} sx={{ mb: 1 }}>
                      <Typography variant="body2">
                        {contactType} Contact Name: {formData[`${contactType.toLowerCase()}Name`]}
                      </Typography>
                      <Typography variant="body2">
                        {contactType} Contact Number: {formData[`${contactType.toLowerCase()}Number`]}
                      </Typography>
                      <Typography variant="body2">
                        {contactType} Contact Email: {formData[`${contactType.toLowerCase()}Email`]}
                      </Typography>
                      <Typography variant="body2">
                        Receive Reports: {formData[`${contactType.toLowerCase()}ReceiveReports`] ? 'Yes' : 'No'}
                      </Typography>
                      <Typography variant="body2">
                        Call for Alerts: {formData[`${contactType.toLowerCase()}CallForAlerts`] ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  ))}
                  {otherContacts && otherContacts.length > 0 && (
                    <Box>
                      {otherContacts.map((contact, index) => (
                        <Box key={index} sx={{ mb: 1 }}>
                          <Typography variant="body2">
                            Other Contact {index + 1} Name: {contact.name}
                          </Typography>
                          <Typography variant="body2">
                            Number: {contact.number}
                          </Typography>
                          <Typography variant="body2">
                            Email: {contact.email}
                          </Typography>
                          <Typography variant="body2">
                            Receive Reports: {contact.receiveReports ? 'Yes' : 'No'}
                          </Typography>
                          <Typography variant="body2">
                            Call for Alerts: {contact.callForAlerts ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {summaryPage === 3 && (
              <Box>
                <Typography variant="subtitle1">Event Management and Driver Coaching</Typography>
                {[
                  'Over Speeding',
                  'Unauthorized Stops',
                  'Cellphone Usage',
                  'Smoking',
                  'Passenger in-cab/ Illegal passenger collection',
                  'Seatbelt Compliance',
                  'All Stops on National Highways',
                  'No Go Zone management and alerts',
                  'Safe Zone Exit and Entry'
                ].map((event, index) => (
                  <Typography key={index} variant="body2">
                    {event}: {formData[event] === 'Yes' ? 'Yes' : 'No'}
                  </Typography>
                ))}
              </Box>
            )}
            {summaryPage === 4 && (
              <Box>
                <Typography variant="subtitle1">Needs Analysis</Typography>
                <Typography variant="body2">
                  Speed Violation Limit: {formData.speedViolationLimit}
                </Typography>
                <Typography variant="body2">
                  Speed Violation Duration before alert: {formData.speedViolationDuration}
                </Typography>
                <Typography variant="body2">
                  Operating hours: {formData.operatingHours}
                </Typography>
                <Typography variant="body2">
                  Parking Outside Depot After Hours: {formData.parkingOutsideDepot}
                </Typography>
                <Typography variant="body2">
                  Harsh Acceleration/Braking: {formData.harshAccelerationBraking}
                </Typography>
                <Typography variant="body2">
                  Excessive Idling: {formData.excessiveIdling}
                </Typography>
              </Box>
            )}
            {summaryPage === 5 && (
              <Box>
                <Typography variant="subtitle1">Driver Details</Typography>
                {formData.drivers && formData.drivers.map((driver, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="body2">Name: {driver.name}</Typography>
                    <Typography variant="body2">
                      Contact Number: {driver.contactNumber}
                    </Typography>
                    <Typography variant="body2">ID Number: {driver.idNumber}</Typography>
                    <Typography variant="body2">
                      Vehicle Registration: {driver.vehicleRegistration}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setSummaryPage(prev => Math.max(prev - 1, 0))}
                disabled={summaryPage === 0}
              >
                Previous Section
              </Button>
              <Typography variant="body2">Page {summaryPage + 1} of 6</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setSummaryPage(prev => Math.min(prev + 1, 5))}
                disabled={summaryPage === 5}
              >
                Next Section
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Save Client
            </Button>
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mb: 2 }}>
              Back
            </Button>
          </Box>
        )}
      </Box>

      {/* FINAL MODAL */}
      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle>Confirm Finalization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure all information is filled out correctly?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Yes, Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        severity={snackbar.severity}
        ContentProps={{ style: { backgroundColor: 'green' } }}
      />
    </Box>
  );
};

export default AddClientWizard;
