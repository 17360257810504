import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  CircularProgress,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  useTheme,
} from '@mui/material';
// NEW IMPORT for Grid2
import Grid2 from '@mui/material/Grid2';
import { useFetchClientQuery } from '../../Features/API/apiSlice';

const ClientDetails = () => {
  const { group_id } = useParams(); // Matches the URL param
  const navigate = useNavigate();
  const theme = useTheme();

  // Fetch client data
  const { data: clientData, isLoading, error } = useFetchClientQuery(group_id);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !clientData) {
    return (
      <Typography variant="h6" color="error">
        Error loading client details.
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Back Button + Main Title */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mr: 2 }}>
          Back to Clients
        </Button>
        <Typography variant="h4">
          {clientData.company_name} – Client Details
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* === OVERVIEW CARD === */}
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title={clientData.company_name}
          subheader={
            // Display branch name if available (using branch.name), otherwise show N/A
            (clientData.branch && clientData.branch.name)
              ? `Branch: ${clientData.branch.name}`
              : 'Branch: N/A'
          }
          sx={{
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            py: 2
          }}
        />
        <CardContent>
          {/* Use Grid2 for a 2-column layout */}
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Company Number:</strong> {clientData.company_number}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {clientData.company_email ? clientData.company_email : 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Branch:</strong> {(clientData.branch && clientData.branch.name) ? clientData.branch.name : 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>FleetCam Pulse:</strong> {clientData.fleet_cam_pulse ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Bureau Plan:</strong>{' '}
                {clientData.bureau_plan && clientData.bureau_plan.length > 0
                  ? clientData.bureau_plan.join(', ')
                  : 'N/A'}
              </Typography>
              {clientData.sales_rep && (
                <Typography variant="body1">
                  <strong>Sales Rep:</strong> {clientData.sales_rep.first_name} {clientData.sales_rep.last_name}
                </Typography>
              )}
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Depot Address:</strong> {clientData.depot_address || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Customer Critical Areas:</strong>{' '}
                {clientData.customer_critical_areas || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Notes & Special Requirements:</strong>{' '}
                {clientData.notes_and_special_requirements || 'N/A'}
              </Typography>
              {clientData.notes && (
                <Typography variant="body1">
                  <strong>Additional Notes:</strong> {clientData.notes}
                </Typography>
              )}
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>

      <Divider sx={{ my: 4 }} />

      {/* MAIN GRID for all sub-sections */}
      <Grid2 container spacing={4}>
        {/* === CONTACTS === */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Contacts" />
            <CardContent>
              {clientData.contacts && clientData.contacts.length > 0 ? (
                clientData.contacts.map((contact, idx) => (
                  <Box key={idx} sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      {contact.contact_type} Contact
                    </Typography>
                    <Typography variant="body2">
                      <strong>Name:</strong> {contact.name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Number:</strong> {contact.number}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Email:</strong> {contact.email}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Receive Reports:</strong>{' '}
                      {contact.receive_reports ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Call for Alerts:</strong>{' '}
                      {contact.call_for_alerts ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>No contacts available.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid2>

        {/* === DRIVERS === */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Drivers" />
            <CardContent>
              {clientData.drivers && clientData.drivers.length > 0 ? (
                clientData.drivers.map((driver, idx) => (
                  <Box key={idx} sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Driver {idx + 1}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Name:</strong> {driver.name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Contact Number:</strong> {driver.contact_number}
                    </Typography>
                    <Typography variant="body2">
                      <strong>ID Number:</strong> {driver.id_number}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Vehicle Registration:</strong>{' '}
                      {driver.vehicle_registration}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>No drivers available.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid2>

        {/* === CALL DRIVERS SETTINGS === */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Call Drivers Settings" />
            <CardContent>
              <Typography variant="body1">
                <strong>Overspeeding:</strong> {clientData.call_drivers_for_overspeeding ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Unauthorized Stops:</strong> {clientData.call_drivers_for_unauthorized_stops ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Passengers in Vehicles:</strong> {clientData.call_drivers_for_passengers ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Cellphone Usage:</strong> {clientData.call_drivers_for_cellphone_usage ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Smoking:</strong> {clientData.call_drivers_for_smoking ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Seatbelt:</strong> {clientData.call_drivers_for_seatbelt ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1">
                <strong>Camera Tampering:</strong> {clientData.call_drivers_for_camera_tampering ? 'Yes' : 'No'}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* === EVENT MANAGEMENT === */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Event Management & Driver Coaching" />
            <CardContent>
              {clientData.event_management ? (
                <Box>
                  <Typography variant="body2">
                    <strong>Over Speeding:</strong>{' '}
                    {clientData.event_management.over_speeding ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Unauthorized Stops:</strong>{' '}
                    {clientData.event_management.unauthorized_stops ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Cellphone Usage:</strong>{' '}
                    {clientData.event_management.cellphone_usage ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Smoking:</strong>{' '}
                    {clientData.event_management.smoking ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Passenger in Cab:</strong>{' '}
                    {clientData.event_management.passenger_in_cab ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Seatbelt Compliance:</strong>{' '}
                    {clientData.event_management.seatbelt_compliance ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>All Stops on National Highways:</strong>{' '}
                    {clientData.event_management.all_stops_on_national_highways
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>No Go Zone Management:</strong>{' '}
                    {clientData.event_management.no_go_zone_management ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Safe Zone Exit/Entry:</strong>{' '}
                    {clientData.event_management.safe_zone_exit_and_entry
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </Box>
              ) : (
                <Typography>No event management settings available.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid2>

        {/* === NEEDS ANALYSIS === */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Needs Analysis" />
            <CardContent>
              {clientData.needs_analysis ? (
                <Box>
                  <Typography variant="body2">
                    <strong>Speed Violation Limit:</strong>{' '}
                    {clientData.needs_analysis.speed_violation_limit} km/h
                  </Typography>
                  <Typography variant="body2">
                    <strong>Speed Violation Duration:</strong>{' '}
                    {clientData.needs_analysis.speed_violation_duration} seconds
                  </Typography>
                  <Typography variant="body2">
                    <strong>Operating Hours:</strong>{' '}
                    {clientData.needs_analysis.operating_hours} hours
                  </Typography>
                  <Typography variant="body2">
                    <strong>Parking Outside Depot:</strong>{' '}
                    {clientData.needs_analysis.parking_outside_depot} hours
                  </Typography>
                  <Typography variant="body2">
                    <strong>Harsh Acceleration/Braking:</strong>{' '}
                    {clientData.needs_analysis.harsh_acceleration_braking
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Excessive Idling:</strong>{' '}
                    {clientData.needs_analysis.excessive_idling ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              ) : (
                <Typography>No needs analysis data available.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid2>

        {/* === REPORTS REQUIRED (If storing individually in the model, show them; 
               or if you have clientData.reports from a separate array, adapt accordingly) */}
        <Grid2 xs={12} md={6}>
          <Card>
            <CardHeader title="Reports Required" />
            <CardContent>
              {/* 
                If you no longer store an array 'clientData.reports',
                but instead store booleans like 'report_detailed_trip', etc., 
                show them individually:
              */}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                  <strong>Detailed Trip Report:</strong>{' '}
                  {clientData.report_detailed_trip ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_detailed_trip_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Trip Summary:</strong>{' '}
                  {clientData.report_trip_summary ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_trip_summary_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Speed Violation Report:</strong>{' '}
                  {clientData.report_speed_violation ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_speed_violation_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Event Report:</strong>{' '}
                  {clientData.report_event ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_event_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Toll Report:</strong>{' '}
                  {clientData.report_toll ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_toll_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Zone In/Out Report:</strong>{' '}
                  {clientData.report_zone_in_out ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_zone_in_out_frequency || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Driver Scorecard Report:</strong>{' '}
                  {clientData.report_driver_scorecard ? 'Yes' : 'No'} - Frequency:{' '}
                  {clientData.report_driver_scorecard_frequency || 'N/A'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      {/* === VEHICLES & DEVICES SECTION === */}
      {clientData.vehicles && clientData.vehicles.length > 0 && (
        <>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h6" gutterBottom>
            Vehicles and Devices
          </Typography>
          <Grid2 container spacing={3}>
            {clientData.vehicles.map((vehicle) => (
              <Grid2 xs={12} key={vehicle.id}>
                <Card variant="outlined">
                  <CardHeader
                    title={vehicle.name}
                    subheader={`Plate: ${vehicle.plate_number} | Reg: ${vehicle.registration_number}`}
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Devices
                    </Typography>
                    {vehicle.devices && vehicle.devices.length > 0 ? (
                      <Grid2 container spacing={2}>
                        {vehicle.devices.map((device) => (
                          <Grid2 xs={12} sm={6} md={4} key={device.device_id || device.id}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                              <Typography variant="body2">
                                <strong>Device ID:</strong>{' '}
                                {device.device_id || device.id}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Type:</strong> {device.device_type}
                              </Typography>
                              <Typography variant="body2">
                                <strong>IMEI:</strong> {device.imei}
                              </Typography>
                            </Paper>
                          </Grid2>
                        ))}
                      </Grid2>
                    ) : (
                      <Typography variant="body2">
                        No devices available for this vehicle.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </>
      )}
    </Container>
  );
};

export default ClientDetails;
