import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';

const MapComponent = ({ center, markers }) => {
  const containerStyle = {
    width: '100%',
    height: '800px',
  };

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && markers.length) {
      const google = window.google;

      markers.forEach((marker) => {
        new google.maps.marker.AdvancedMarkerElement({
          position: marker.position,
          map: mapRef.current,
        });
      });
    }
  }, [markers]);

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyDzUHRGsFEOCeBAMvCleZKR1XDYd5ZWtF8"
      libraries={['marker']}
      version="weekly"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={{
          mapId: '4c1be181581b3d50', // Replace with your actual Map ID
        }}
        onLoad={(map) => {
            console.log('Map Loaded', map);
          mapRef.current = map;
        }}
      />
    </LoadScriptNext>
  );
};

export default MapComponent;
