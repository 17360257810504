import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Features/Authentication/authSlice';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import FleetCamLogo from '../Photos/FleetCam - Logo.png';
import TruckWireframe from '../Photos/TruckWireframe.png';

const Login = () => {
  // Redux & Router
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = useSelector((state) => state.auth);

  // Local state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Handlers
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }))
      .unwrap()
      .then((response) => {
        if (response.require_password_reset) {
          navigate('/reset-password');
        } else {
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        console.error('Login failed:', err);
      });
  };

  // Common TextField style override to maintain the same translucent background on hover/focus
  const textFieldStyles = {
    mb: 2,
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#fff',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
      // Force the translucent background on Chrome autofill
      '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px rgba(255, 255, 255, 0.1) inset !important',
        WebkitTextFillColor: '#fff !important',
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
      },
      // Also for hover & focus states when autofilled
      '& input:-webkit-autofill:hover, & input:-webkit-autofill:focus': {
        WebkitBoxShadow: '0 0 0 1000px rgba(255, 255, 255, 0.1) inset !important',
        WebkitTextFillColor: '#fff !important',
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#ccc',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#ccc',
    },
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${TruckWireframe})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', md: 'flex-end' },
        p: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          mr: { md: 8 },
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          p: 4,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: '#fff',
          boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
        }}
      >
        {/* Logo */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Box
            component="img"
            src={FleetCamLogo}
            alt="FleetCam Logo"
            sx={{ width: '60%', maxWidth: 200 }}
          />
        </Box>

        {/* Title */}
        <Typography
          component="h1"
          variant="h5"
          align="center"
          sx={{ mb: 3, fontWeight: 'bold' }}
        >
          Log in to your Account
        </Typography>

        {/* Form */}
        <Box component="form" onSubmit={handleSubmit}>
          {/* Username */}
          <TextField
            required
            fullWidth
            label="Username"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={textFieldStyles}
          />

          {/* Password */}
          <TextField
            required
            fullWidth
            label="Password"
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ color: '#fff' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={textFieldStyles}
          />

          <FormControlLabel
            control={<Checkbox sx={{ color: '#fff' }} />}
            label="Remember me"
            sx={{ color: '#fff', mb: 2 }}
          />

          {error && (
            <Typography color="error" variant="body2" sx={{ mb: 1 }}>
              {error}
            </Typography>
          )}

          <Button type="submit" variant="contained" fullWidth sx={{ mt: 1, mb: 2 }}>
            LOG IN
          </Button>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Link href="/reset-password" variant="body2" sx={{ color: '#bbb' }}>
              Forgot Password?
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
