import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Paper,
  
  Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Link as RouterLink } from 'react-router-dom';
import { useFetchVehiclesForGroupsQuery } from '../../Features/API/apiSlice';
import Grid2 from '@mui/material/Grid2';

const ManageVehicles = () => {
  const { data: vehiclesResponse, error, isLoading } = useFetchVehiclesForGroupsQuery(); // Fetch vehicles
  const [vehicles, setVehicles] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    if (vehiclesResponse && vehiclesResponse.vehicles) {
      setVehicles(vehiclesResponse.vehicles);
    }
  }, [vehiclesResponse]);

  const handleOpenDeleteDialog = (vehicle) => {
    setVehicleToDelete(vehicle);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setVehicleToDelete(null);
  };

  const handleConfirmDelete = async () => {
    // Your delete logic here
    handleCloseDeleteDialog();
  };

  const handleMoreClick = (event, vehicle) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  const columns = [
    { field: 'name', headerName: 'Vehicle Name', flex: 1 },
    { field: 'group_title', headerName: 'Group (Client)', flex: 1 },
    { field: 'driver', headerName: 'Driver', flex: 1 },
    {
      field: 'deviceCount',
      headerName: 'Number of Devices',
      flex: 1,
      renderCell: (params) => params.row.devices.length,
    },
    {
      field: 'deviceIds',
      headerName: 'Device IDs',
      flex: 1,
      renderCell: (params) => params.row.devices.map((device) => device.imei || 'N/A').join(', '),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Tooltip title="More actions">
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(event) => handleMoreClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedVehicle?.id === params.row.id)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => {/* Your edit logic */}}>
              <EditIcon sx={{ mr: 1 }} />
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleOpenDeleteDialog(params.row)}>
              <DeleteIcon sx={{ mr: 1 }} />
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  const rows = vehicles.map((vehicle) => ({
    id: vehicle.id,
    name: vehicle.name,
    group_title: vehicle.group_title,
    driver: vehicle.driver,
    devices: vehicle.devices,
  }));

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 7 }}>
        <Grid2 container spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Grid2 item xs={12} md={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink component={RouterLink} to="/" color="inherit">
                Home
              </MuiLink>
              <MuiLink component={RouterLink} to="/dashboard" color="inherit">
                Dashboard
              </MuiLink>
              <MuiLink component={RouterLink} to="/settings" color="inherit">
                Settings
              </MuiLink>
              <Typography color="textPrimary">Manage Vehicles</Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              Manage Vehicles
            </Typography>
          </Grid2>
          <Grid2 item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DirectionsCarIcon />}
            >
              Add Vehicle
            </Button>
          </Grid2>
        </Grid2>

        <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  padding: 1,
                },
              }}
            />
          )}
        </Paper>

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete vehicle {vehicleToDelete?.name}? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default ManageVehicles;
