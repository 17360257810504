import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Box,
  Paper,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RefreshIcon from '@mui/icons-material/Refresh';

import Grid2 from '@mui/material/Grid2';

import CreateEventModal from './CreateEventModal';
import NewInstallWizard from '../TestWizards/NewInstallWizard';
import RepairWizard from '../TestWizards/RepairWizard';
import DeInstallWizard from '../TestWizards/DeInstallWizard';

const CustomTreeView = ({ groups = [], onRefresh }) => {
  const theme = useTheme();
  const treeViewRef = useRef(null);

  // =========================
  // Local States
  // =========================
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [flaggedVehicles, setFlaggedVehicles] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [vehicleAnchorEl, setVehicleAnchorEl] = useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

  // Vehicle & Device selection
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedGroupTitle, setSelectedGroupTitle] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  // Modals & Wizards
  const [openEventModal, setOpenEventModal] = useState(false);
  const [openInstallWizard, setOpenInstallWizard] = useState(false);
  const [openRepairWizard, setOpenRepairWizard] = useState(false);
  const [openDeInstallWizard, setOpenDeInstallWizard] = useState(false);

  // Helper to decide which wizard to open
  const [wizardToOpen, setWizardToOpen] = useState(null);

  // =========================
  // Effects
  // =========================

  // Load flagged vehicles from localStorage
  useEffect(() => {
    const savedFlags = localStorage.getItem('flaggedVehicles');
    if (savedFlags) {
      setFlaggedVehicles(JSON.parse(savedFlags));
    }
  }, []);

  // Save to localStorage whenever flaggedVehicles changes
  useEffect(() => {
    localStorage.setItem('flaggedVehicles', JSON.stringify(flaggedVehicles));
  }, [flaggedVehicles]);

  // Decide which wizard to open
  useEffect(() => {
    if (!wizardToOpen) return;
    switch (wizardToOpen) {
      case 'install':
      case 'reinstall':
        setOpenInstallWizard(true);
        break;
      case 'repair':
        setOpenRepairWizard(true);
        break;
      case 'deinstall':
        setOpenDeInstallWizard(true);
        break;
      default:
        break;
    }
    setWizardToOpen(null);
  }, [wizardToOpen]);

  // =========================
  // Helpers
  // =========================
  const getItemId = (type, id) => `${type}-${id}`;

  const handleExpandedItemsChange = useCallback((_, itemIds) => {
    setExpandedItems(itemIds);
  }, []);

  // Expand / Collapse
  const handleCollapseAll = () => {
    setExpandedItems([]);
  };
  const handleExpandAll = () => {
    const allGroupIds = groups.map((g) => getItemId('group', g.group_id));
    setExpandedItems(allGroupIds);
  };

  // Scrolling
  const handleScrollToBottom = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = treeViewRef.current.scrollHeight;
    }
  };
  const handleScrollToTop = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = 0;
    }
  };

  // Reset flagged colors
  const handleResetFlags = () => {
    setFlaggedVehicles({});
  };

  // Toggle color for flagged vehicles
  const handleFlagToggle = (vehicleId) => {
    setFlaggedVehicles((prev) => {
      const currentState = prev[vehicleId] || 'grey';
      const nextState = {
        grey: 'green',
        green: 'yellow',
        yellow: 'red',
        red: 'grey',
      }[currentState];
      return { ...prev, [vehicleId]: nextState };
    });
  };

  // Menus
  const handleMoreClick = useCallback(
    (event, groupId, vehicle, device, level) => {
      if (level === 'vehicle') {
        setVehicleAnchorEl(event.currentTarget);
        setSelectedVehicle(vehicle);
        setSelectedGroupTitle(vehicle.group_title || '');
        setSelectedGroupId(groupId);
      } else {
        setAnchorEl(event.currentTarget);
        setSelectedDevice({
          device_id: device.device_id,
          imei: device.imei,
          make: vehicle.make || 'Unknown Make',
          model: vehicle.model || 'Unknown Model',
          colour: vehicle.colour || 'Unknown Colour',
          plate_number: vehicle.plate_number || 'Unknown Plate Number',
          device_type: device.device_type,
        });
        setSelectedGroupTitle(vehicle.group_title || '');
        setSelectedVehicle(vehicle);
      }
    },
    []
  );

  const handleCloseMenu = () => setAnchorEl(null);
  const handleCloseVehicleMenu = () => setVehicleAnchorEl(null);
  const handleOpenSubmenu = (event) => setSubmenuAnchorEl(event.currentTarget);
  const handleCloseSubmenu = () => setSubmenuAnchorEl(null);

  // Modals / Wizards
  const handleCreateEvent = () => {
    setOpenEventModal(true);
  };

  const handleOpenWizard = (type) => {
    setWizardToOpen(type);
  };

  // =========================
  // Search Filter
  // =========================
  const filteredGroups = useMemo(() => {
    if (!groups.length) return [];
    return groups
      .map((group) => {
        const filteredVehicles = group.vehicles
          .map((vehicle) => {
            // Filter devices
            const filteredDevices = vehicle.devices.filter(
              (device) =>
                device.device_id
                  ?.toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                device.imei?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            // Check if vehicle itself matches the query
            const matchesVehicle =
              vehicle.plate_number
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              vehicle.plan?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              filteredDevices.length > 0;

            if (matchesVehicle) {
              return {
                ...vehicle,
                devices:
                  filteredDevices.length > 0
                    ? filteredDevices
                    : vehicle.devices,
              };
            }
            return null;
          })
          .filter(Boolean);

        const matchesGroup =
          group.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          filteredVehicles.length > 0;

        if (matchesGroup) {
          return {
            ...group,
            vehicles:
              filteredVehicles.length > 0 ? filteredVehicles : group.vehicles,
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [groups, searchQuery]);

  // =========================
  // Render
  // =========================
  return (
    <Grid2
      container
      direction="column"
      spacing={2}
      sx={{
        minWidth: 120,
        mt: 2,
      }}
    >
      {/* -- Top App Bar -- */}
      <Grid2 item xs={12} sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <AppBar
          position="sticky"
          sx={{
            borderRadius: 2,
            background: 'rgba(0, 0, 0, 0.85)',
            backdropFilter: 'blur(6px)',
            top: 0,
          }}
        >
          <Toolbar sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {/* Search Field placed on the left with a smaller fixed width */}
            <Box sx={{ width: '150px' }}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="small"
                fullWidth
                sx={{
                  backgroundColor: '#2c2c2c',
                  borderRadius: 2,
                  '& .MuiOutlinedInput-input': { color: '#fff' },
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.primary.main}`,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ mr: 1, color: theme.palette.grey[400] }} />
                  ),
                }}
              />
            </Box>
            {/* Spacer pushes icons to the right */}
            <Box sx={{ flexGrow: 1 }} />
            {/* Refresh Icon Button */}
            <Tooltip title="Refresh">
              <IconButton
                onClick={onRefresh}
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            {/* Additional Action Icons */}
            <Tooltip title="Reset Flags">
              <IconButton
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
                onClick={handleResetFlags}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Expand All">
              <IconButton
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
                onClick={handleExpandAll}
              >
                <UnfoldMoreIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Collapse All">
              <IconButton
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
                onClick={handleCollapseAll}
              >
                <UnfoldLessIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Scroll to Bottom">
              <IconButton
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
                onClick={handleScrollToBottom}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Scroll to Top">
              <IconButton
                sx={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0b4885' },
                }}
                onClick={handleScrollToTop}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </Grid2>

      {/* -- Tree View Section -- */}
      <Grid2
        item
        xs={12}
        component={Paper}
        elevation={2}
        sx={{
          p: 2,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
          backgroundColor: '#1a1a1a',
          color: '#fff',
          zIndex: theme.zIndex.drawer - 1,
        }}
        ref={treeViewRef}
      >
        <SimpleTreeView
          aria-label="vehicle tree"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
        >
          {filteredGroups.length > 0 ? (
            filteredGroups.map((group) => (
              <TreeItem
                key={getItemId('group', group.group_id)}
                itemId={getItemId('group', group.group_id)}
                label={
                  <Grid2 container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid2 item>
                      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        {group.title}
                      </Typography>
                    </Grid2>
                    <Grid2 item>
                      <Typography
                        variant="caption"
                        sx={{
                          backgroundColor: theme.palette.success.light,
                          borderRadius: '12px',
                          padding: '2px 8px',
                          color: '#fff',
                          marginLeft: '8px',
                        }}
                      >
                        {group.vehicles.length}
                      </Typography>
                    </Grid2>
                  </Grid2>
                }
              >
                {group.vehicles.map((vehicle) => (
                  <TreeItem
                    key={getItemId('vehicle', vehicle.plate_number)}
                    itemId={getItemId('vehicle', vehicle.plate_number)}
                    label={
                      <Grid2 container alignItems="center" spacing={1} wrap="nowrap">
                        <Grid2 item>
                          <DirectionsCarIcon
                            sx={{
                              color: flaggedVehicles[vehicle.plate_number] || 'grey',
                              cursor: 'pointer',
                              transition: 'color 0.3s',
                              '&:hover': {
                                color: theme.palette.primary.main,
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFlagToggle(vehicle.plate_number);
                            }}
                          />
                        </Grid2>
                        <Grid2 item>
                          <Typography variant="body2" sx={{ fontWeight: 'medium', color: '#fff' }}>
                            {vehicle.plate_number} - {vehicle.plan}
                          </Typography>
                        </Grid2>
                        {/* Triple-dot for Vehicle */}
                        <Grid2 item>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoreClick(e, group.group_id, vehicle, null, 'vehicle');
                            }}
                            size="small"
                          >
                            <MoreVertIcon fontSize="small" sx={{ color: '#fff' }} />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                    }
                  >
                    {/* Device Items */}
                    {vehicle.devices.map((device) => (
                      <TreeItem
                        key={getItemId('device', device.device_id)}
                        itemId={getItemId('device', device.device_id)}
                        label={
                          <Grid2 container alignItems="center" spacing={1} wrap="nowrap" sx={{ pl: 4 }}>
                            <Grid2 item>
                              <Typography variant="caption" sx={{ fontWeight: 500, color: '#ccc' }}>
                                {device.device_type}
                              </Typography>
                            </Grid2>
                            {/* Device Menu Button */}
                            <Grid2 item>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMoreClick(e, group.group_id, vehicle, device, 'device');
                                }}
                                size="small"
                              >
                                <MoreVertIcon fontSize="small" sx={{ color: '#fff' }} />
                              </IconButton>
                            </Grid2>
                          </Grid2>
                        }
                      >
                        <TreeItem
                          key={getItemId('device-id', device.device_id)}
                          itemId={getItemId('device-id', device.device_id)}
                          label={
                            <Typography variant="caption" sx={{ pl: 3, color: '#ccc' }}>
                              Device ID: {device.imei || 'N/A'}
                            </Typography>
                          }
                        />
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))
          ) : (
            <Grid2 container justifyContent="center" alignItems="center" sx={{ p: 2 }}>
              <Typography variant="body2" color="#fff">
                No results found.
              </Typography>
            </Grid2>
          )}
        </SimpleTreeView>
      </Grid2>

      {/* -- Vehicle Menu -- */}
      <Menu
        anchorEl={vehicleAnchorEl}
        open={Boolean(vehicleAnchorEl)}
        onClose={handleCloseVehicleMenu}
      >
        <MenuItem onClick={handleCloseVehicleMenu}>Edit Vehicle</MenuItem>
        <MenuItem onClick={handleCreateEvent}>Create Event</MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseVehicleMenu();
            handleOpenWizard('install');
          }}
        >
          New Install
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseVehicleMenu();
            handleOpenWizard('reinstall');
          }}
        >
          Re-Install
        </MenuItem>
      </Menu>

      {/* -- Device Menu -- */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOpenWizard('repair');
          }}
        >
          Repair
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOpenWizard('deinstall');
          }}
        >
          De-Install
        </MenuItem>
      </Menu>

      {/* -- Submenu (if needed) -- */}
      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseSubmenu();
            handleOpenWizard('install');
          }}
        >
          New Install
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseSubmenu();
            handleOpenWizard('repair');
          }}
        >
          Repair
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseSubmenu();
            handleOpenWizard('deinstall');
          }}
        >
          De-Install
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseSubmenu();
            handleOpenWizard('reinstall');
          }}
        >
          Re-Install
        </MenuItem>
      </Menu>

      {/* -- Create Event Modal -- */}
      {openEventModal && (
        <CreateEventModal
          open={openEventModal}
          onClose={() => setOpenEventModal(false)}
          vehicle={selectedVehicle}
          groupTitle={selectedGroupTitle}
        />
      )}

      {/* -- Wizards -- */}
      {openInstallWizard && (
        <NewInstallWizard
          open={openInstallWizard}
          selectedCompany={selectedGroupTitle}
          selectedVehicle={selectedVehicle}
          selectedDevice={selectedDevice}
          selectedGroupId={selectedGroupId}
          onClose={() => setOpenInstallWizard(false)}
        />
      )}
      {openRepairWizard && (
        <RepairWizard
          open={openRepairWizard}
          selectedCompany={selectedGroupTitle}
          selectedVehicle={selectedVehicle}
          selectedDevice={selectedDevice}
          selectedGroupId={selectedGroupId}
          onClose={() => setOpenRepairWizard(false)}
        />
      )}
      {openDeInstallWizard && (
        <DeInstallWizard
          open={openDeInstallWizard}
          selectedCompany={selectedGroupTitle}
          selectedVehicle={selectedVehicle}
          selectedDevice={selectedDevice}
          onClose={() => setOpenDeInstallWizard(false)}
        />
      )}
    </Grid2>
  );
};

export default CustomTreeView;
