// src/Features/Authentication/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginUser } from '../API/api';

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const response = await loginUser({ username, password });
            return {
                token: response.data.token,
                user: response.data.user,
            };
        } catch (error) {
            return rejectWithValue(error.response.data.error || 'Login failed');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: sessionStorage.getItem('token') || null,
        user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null,
        error: null,
        status: 'idle',
    },
    reducers: {
        logout(state) {
            state.token = null;
            state.user = null;
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.token;
                state.user = action.payload.user;
                sessionStorage.setItem('token', action.payload.token);
                sessionStorage.setItem('user', JSON.stringify(action.payload.user));
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
