import React from 'react';
import MapComponent from '../BureauPortal/Maps';

export default function ExcoLandingPage() {
  const center = { lat: 36.7783, lng: -119.4179 }; // California coordinates
  const markers = [
    { position: { lat: 37.7749, lng: -122.4194 } }, // San Francisco
    { position: { lat: 34.0522, lng: -118.2437 } }, // Los Angeles
  ];

  const containerStyle = {
    width: '40%',
    height: '400px',
  };

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '30%', height: '80%' }}>
        <MapComponent center={center} markers={markers} />
      </div>
    </div>
  );
}
