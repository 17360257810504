// DeInstallWizard.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateNewDeInstallTestMutation } from '../../Features/API/apiSlice';

const DeInstallWizard = ({ open, onClose, selectedCompany, selectedVehicle, selectedDevice }) => {
  // Define the steps for the wizard
  const steps = ['Reasons for De-Install', 'Confirmation', 'Reference Number'];

  // State to manage the current active step
  const [activeStep, setActiveStep] = useState(0);

  // State to store form data (user comments)
  const [formData, setFormData] = useState({
    deinstallComments: '',
  });

  // State to store the reference number returned from the backend
  const [referenceNumber, setReferenceNumber] = useState(null);

  // RTK Query mutation hook
  const [createNewDeInstallTest, { isLoading, error }] = useCreateNewDeInstallTestMutation();

  // Function to move to the next step
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  // Function to reset the wizard and close the dialog
  const handleFinish = () => {
    setActiveStep(0);
    setFormData({ deinstallComments: '' });
    setReferenceNumber(null);
    onClose();
  };

  // Function to handle the approval (de-install) action
  const handleApprove = async () => {
    try {
      console.log('Selected Device:', selectedDevice);
      if (!selectedDevice || !selectedDevice.device_id) {
        throw new Error('Selected device is invalid.');
      }

      // Call the mutation with the required payload
      const response = await createNewDeInstallTest({
        device_id: selectedDevice.device_id,
        status: 'approved',
        comments: formData.deinstallComments,
      }).unwrap();

      console.log('DeInstall Response:', response);
      const refNo = response.data?.reference_number; // Ensure this is the correct path to the reference number
      setReferenceNumber(refNo);

      // Move to the next step (Reference Number)
      handleNext();
    } catch (err) {
      console.error('Failed to de-install device:', err);
    }
  };

  // Function to handle the failure (de-install) action
  const handleFail = async () => {
    try {
      console.log('Selected Device:', selectedDevice);
      if (!selectedDevice || !selectedDevice.device_id) {
        throw new Error('Selected device is invalid.');
      }

      // Call the mutation with the required payload
      const response = await createNewDeInstallTest({
        device_id: selectedDevice.device_id,
        status: 'failed',
        comments: formData.deinstallComments,
      }).unwrap();

      console.log('DeInstall Response:', response);
      const refNo = response.data?.reference_number; // Ensure this is the correct path to the reference number
      setReferenceNumber(refNo);

      // Move to the next step (Reference Number)
      handleNext();
    } catch (err) {
      console.error('Failed to fail de-install device:', err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        De-Install Wizard
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          {/* Stepper to indicate the current step */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Content based on the current step */}
          <Box sx={{ mt: 2, mb: 1 }}>
            {/* Step 0: Reasons for De-Install */}
            {activeStep === 0 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Reasons for De-Install
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Comments"
                  value={formData.deinstallComments}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      deinstallComments: e.target.value,
                    }))
                  }
                  variant="outlined"
                  disabled={isLoading}
                />
              </Box>
            )}

            {/* Step 1: Confirmation */}
            {activeStep === 1 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Are you sure you want to De-Install this device?
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Device IMEI: {selectedDevice?.imei || 'N/A'} (
                  {selectedDevice?.device_type || 'Unknown Type'})
                </Typography>
                <Typography variant="body2">
                  Once you proceed, the device will be marked as de-installed in the system.
                </Typography>

                {/* Action Buttons: Approve and Fail */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleFail}
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Fail'}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApprove}
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Approve'}
                  </Button>
                </Box>

                {/* Display Backend Error if any */}
                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error.data?.error || 'Failed to de-install device. Please try again.'}
                  </Alert>
                )}
              </Box>
            )}

            {/* Step 2: Reference Number */}
            {activeStep === 2 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Reference Number
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {referenceNumber || 'N/A'}
                </Typography>
                <Button variant="contained" onClick={handleFinish}>
                  Finish
                </Button>
              </Box>
            )}
          </Box>

          {/* Navigation Buttons */}
          {activeStep === 0 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button disabled={activeStep === 0} onClick={() => setActiveStep((prev) => prev - 1)}>
                Back
              </Button>
              <Button onClick={handleNext} disabled={!formData.deinstallComments.trim()}>
                Next
              </Button>
            </Box>
          )}
          {/* No nav buttons for step 1, we have Approve/Fail */}
          {/* No nav buttons for step 2, we have "Finish" */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeInstallWizard;
