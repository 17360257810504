import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Badge,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { ReactSVG } from 'react-svg';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleIconOutlined from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

// Your side nav icons
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import BusAlertIconOutlined from '@mui/icons-material/BusAlertOutlined';
import HandymanIconOutlined from '@mui/icons-material/HandymanOutlined';
import BuildIconOutlined from '@mui/icons-material/BuildOutlined';
import AddLocationAltIconOutlined from '@mui/icons-material/AddLocationAltOutlined';
import MinorCrashIconOutlined from '@mui/icons-material/MinorCrashOutlined';
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined';

import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../Features/Authentication/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../Components/Photos/FC Logo 2.png';

// --------------------------
// Updated Styling
// --------------------------

// Dark translucent glass navbar for main row
const GlassAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'rgba(0,0,0,0.6)',
  backdropFilter: 'blur(6px)',
  boxShadow: 'none',
  transition: 'background 0.3s ease',
}));

// Slight hover-scale for icons
const AnimatedIconButton = styled(IconButton)(() => ({
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

// Styled badge for online status
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 10 });
  return React.cloneElement(children, {});
}

export default function BureauPortalNavbar({ toggleMode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useSelector((state) => state.auth.user);

  // Top-right user menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const handleUserMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleUserMenuClose = () => setAnchorEl(null);

  // Collapsible sub-sub items state
  const [technicalOpen, setTechnicalOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  // Toggle for the entire horizontal sub-nav bar beneath the main row
  const [toolbarOpen, setToolbarOpen] = useState(false);

  // Theme toggle
  const handleToggleTheme = () => {
    if (typeof toggleMode === 'function') {
      toggleMode();
    }
  };

  // Logout
  const handleLogoutClick = () => {
    dispatch(logout());
    navigate('/login');
  };

  // Brand click
  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  // Check active route
  const isActive = (path) => location.pathname === path;

  // Side nav items
  const navItems = [
    {
      label: 'Dashboard',
      path: '/bureau-dashboard',
      icon: <DashboardCustomizeOutlinedIcon />,
    },
    {
      label: 'Alert Monitoring',
      path: '/alerts',
      icon: <NotificationsActiveOutlinedIcon />,
    },
    {
      label: 'Event Management',
      path: '/event-management',
      icon: <BusAlertIconOutlined />,
    },
    {
      label: 'Technical',
      collapsible: true,
      isOpen: technicalOpen,
      toggle: () => setTechnicalOpen(!technicalOpen),
      icon: <HandymanIconOutlined />,
      subItems: [
        { label: 'Test Log', path: '/test-log', icon: <BuildIconOutlined /> },
      ],
    },
    {
      label: 'Zone Management',
      path: '/zones',
      icon: <AddLocationAltIconOutlined />,
    },
    {
      label: 'Incident Management',
      path: '/incidents',
      icon: <MinorCrashIconOutlined />,
    },
    {
      label: 'Vehicles',
      collapsible: true,
      isOpen: settingsOpen,
      toggle: () => setSettingsOpen(!settingsOpen),
      icon: <SettingsIcon />,
      subItems: [
        { label: 'General', path: '/settings/general', icon: <BuildIconOutlined /> },
        { label: 'Users', path: '/settings/users', icon: <AccountCircleIconOutlined /> },
        { label: 'Vehicles', path: '/settings/vehicles', icon: <DirectionsCarIconOutlined /> },
      ],
    },
  ];

  // User menu items
  const userMenuItems = [
    { label: 'Settings', icon: <SettingsIcon />, onClick: () => navigate('/settings') },
    { label: 'Profile', icon: <AccountCircleIcon />, onClick: () => navigate('/profile') },
    {
      label: 'Toggle Theme',
      icon: theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />,
      onClick: handleToggleTheme,
    },
    { label: 'Logout', icon: <LogoutIcon />, onClick: handleLogoutClick },
  ];

  // If an item is collapsible, expand sub items; else navigate
  const handleMainItemClick = (item) => {
    if (item.collapsible) {
      item.toggle?.();
    } else if (item.path) {
      navigate(item.path);
    }
  };

  // Sub-item click handler
  const handleSubItemClick = (sub) => {
    if (sub.path) {
      navigate(sub.path);
    }
  };

  return (
    <ElevationScroll>
      <GlassAppBar>
        <Toolbar sx={{ minHeight: 64, position: 'relative' }}>
          {/* Left Section: Logo */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              cursor: 'pointer'
            }}
            onClick={handleLogoClick}
          >
            <img src={Logo} alt="FleetCam Logo" style={{ height: '36px' }} />
          </Box>

          {/* Center Section: Title */}
          <Box
            sx={{ flex: 1, textAlign: 'center', cursor: 'pointer' }}
            onClick={handleLogoClick}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: '1.25rem',
                color: '#fff',
                userSelect: 'none',
              }}
            >
              Bureau Portal
            </Typography>
          </Box>

          {/* Right Section: Theme toggle, subnav toggle, and user avatar */}
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            {!isMobile && (
              <Tooltip title="Toggle Theme">
                <AnimatedIconButton color="inherit" onClick={handleToggleTheme}>
                  {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </AnimatedIconButton>
              </Tooltip>
            )}
            <AnimatedIconButton
              onClick={() => setToolbarOpen(!toolbarOpen)}
              sx={{ ml: 1, color: '#fff' }}
            >
              {toolbarOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </AnimatedIconButton>
            <AnimatedIconButton onClick={handleUserMenuOpen} sx={{ ml: 1, color: '#fff' }}>
              {isMobile ? (
                <MenuIcon />
              ) : (
                <StyledBadge overlap="circular" variant="dot">
                  <Avatar sx={{ bgcolor:'#8FC74B', width: 36, height: 36 }}>
                    {user?.firstName && user?.lastName
                      ? `${user.firstName[0]}${user.lastName[0]}`
                      : <AccountCircleIcon />}
                  </Avatar>
                </StyledBadge>
              )}
            </AnimatedIconButton>
          </Box>
        </Toolbar>

        {/* Floating Subnav */}
        {toolbarOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 64, // placed right below the main toolbar
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                py: 2,
                px: 2,
                borderRadius: '0 0 8px 8px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                // Solid background (no translucency)
                backgroundColor: '#000',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {navItems.map((item, idx) => {
                const active = item.path && isActive(item.path);
                return (
                  <Box
                    key={idx}
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: active ? 'rgba(255,255,255,0.2)' : 'transparent',
                      borderRadius: 2,
                      px: 2,
                      py: 1,
                      '&:hover': {
                        backgroundColor: active
                          ? 'rgba(255,255,255,0.25)'
                          : 'rgba(255,255,255,0.15)',
                      },
                    }}
                    onClick={() => handleMainItemClick(item)}
                  >
                    <IconButton
                      size="small"
                      sx={{
                        color: active ? 'warning.main' : '#fff',
                        mr: 1,
                      }}
                    >
                      {item.icon}
                    </IconButton>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      {item.label}
                    </Typography>
                    {/* Render sub-items if collapsible and open */}
                    {item.collapsible && item.isOpen && item.subItems?.length > 0 && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '100%',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          mt: 1,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          background: 'linear-gradient(90deg, #155a9c, #1e88e5)',
                          borderRadius: 2,
                          px: 2,
                          py: 1,
                          boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item.subItems.map((sub, sIdx) => {
                          const subActive = sub.path && isActive(sub.path);
                          return (
                            <Box
                              key={sIdx}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                backgroundColor: subActive
                                  ? 'rgba(255,255,255,0.2)'
                                  : 'transparent',
                                borderRadius: 2,
                                px: 2,
                                py: 1,
                                '&:hover': {
                                  backgroundColor: subActive
                                    ? 'rgba(255,255,255,0.25)'
                                    : 'rgba(255,255,255,0.1)',
                                },
                              }}
                              onClick={() => handleSubItemClick(sub)}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  color: subActive ? 'warning.main' : '#fff',
                                  mr: 1,
                                }}
                              >
                                {sub.icon}
                              </IconButton>
                              <Typography variant="body2" sx={{ color: '#fff' }}>
                                {sub.label}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {/* User Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}
          sx={{ mt: 1 }}
        >
          {userMenuItems.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleUserMenuClose();
                item.onClick?.();
              }}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              {item.icon}
              <Typography>{item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </GlassAppBar>
    </ElevationScroll>
  );
}
