import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Tooltip,
  Badge,
  IconButton
} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Features/Authentication/authSlice';
import logo from '../Photos/FC Logo 2.png';

const GlassAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  // Dark translucent background
  backgroundColor: 'rgba(0,0,0,0.6)',
  backdropFilter: 'blur(6px)',
  boxShadow: 'none',
  transition: 'background 0.3s ease',
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));



function ElevationScroll(props) {
  const { children } = props;
  useScrollTrigger({ disableHysteresis: true, threshold: 10 });
  // If you want to change style on scroll, you can do it here.
  return React.cloneElement(children);
}

const Navbar = ({ toggleMode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const handleMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleToggleTheme = () => {
    if (typeof toggleMode === 'function') {
      toggleMode();
    }
  };

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  const menuItems = [
    { label: 'Settings', icon: <SettingsIcon />, onClick: () => navigate('/settings') },
    { label: 'Profile', icon: <AccountCircleIcon />, onClick: () => navigate('/profile') },
    {
      label: 'Toggle Theme',
      icon: theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />,
      onClick: handleToggleTheme,
    },
    { label: 'Logout', icon: <LogoutIcon />, onClick: handleLogoutClick },
  ];

  return (
    <ElevationScroll>
      <GlassAppBar>
        <Toolbar sx={{ minHeight: 64 }}>
          {/* Left Section: Logo */}
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src={logo}
              alt="Logo"
              onClick={handleLogoClick}
              sx={{
                height: 36,
                cursor: 'pointer',
              }}
            />
          </Box>

          {/* Center Section: Title */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: '1.25rem',
                color: '#fff',
                userSelect: 'none',
              }}
            >
              Bureau Userport
            </Typography>
          </Box>

          {/* Right Section: Theme Toggle & User Menu */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {!isMobile && (
              <Tooltip title="Toggle Theme">
                <AnimatedIconButton color="inherit" onClick={handleToggleTheme}>
                  {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </AnimatedIconButton>
              </Tooltip>
            )}

            <AnimatedIconButton color="success" onClick={handleMenuOpen} sx={{ ml: 1 }}>
              {isMobile ? (
                <MenuIcon />
              ) : (
                
                  <Avatar
                    alt={user?.name}
                    sx={{
                      bgcolor: '#8FC74B',
                      width: 36,
                      height: 36,
                    }}
                  >
                    {user?.firstName && user?.lastName
                      ? `${user.firstName[0]}${user.lastName[0]}`
                      : <AccountCircleIcon />}
                  </Avatar>
                
              )}
            </AnimatedIconButton>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ mt: 1 }}
            >
              {menuItems.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    handleMenuClose();
                    item.onClick?.();
                  }}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  {item.icon}
                  <Typography>{item.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </GlassAppBar>
    </ElevationScroll>
  );
};

export default Navbar;
