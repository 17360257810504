import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    Breadcrumbs,
    Link as MuiLink,
    Paper,
    Tooltip,
    Chip,
    Avatar,
    
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    useFetchUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useFetchUserGroupsQuery
} from '../../Features/API/authApiSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import Grid2 from '@mui/material/Grid2';

const ManageUsers = () => {
    const { data: users = [], refetch: fetchUsers } = useFetchUsersQuery();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const { data: groups = [] } = useFetchUserGroupsQuery();
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentUser, setCurrentUser] = useState({ username: '', email: '', password: '', first_name: '', last_name: '', group: '' });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleInputChange = (e) => {
        setCurrentUser({
            ...currentUser,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddUser = () => {
        setEditMode(false);
        setCurrentUser({ username: '', email: '', password: '', first_name: '', last_name: '', group: '' });
        setOpen(true);
    };

    const handleEditUser = (user) => {
        setEditMode(true);
        setCurrentUser({
            ...user,
            password: '',
            group: user.groups && user.groups.length > 0 ? user.groups[0].id : '',
        });
        setOpen(true);
    };

    const handleSaveUser = async () => {
        const userData = { ...currentUser, groups: currentUser.group ? [currentUser.group] : [] };
        if (editMode) {
            await updateUser({ userId: currentUser.id, ...userData });
        } else {
            await createUser(userData);
        }
        fetchUsers();
        setOpen(false);
    };

    const handleCloseUserModal = () => {
        setOpen(false);
    };

    const handleOpenDeleteDialog = (user) => {
        setUserToDelete(user);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setUserToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (userToDelete) {
            await deleteUser(userToDelete.id);
            fetchUsers();
            handleCloseDeleteDialog();
        }
    };

    const columns = [
        { 
            field: 'fullName', 
            headerName: 'Full Name', 
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ mr: 2 }}>{params.row.fullName.charAt(0)}</Avatar>
                    {params.row.fullName}
                </Box>
            )
        },
        { field: 'email', headerName: 'Email', flex: 1 },
        { 
            field: 'group', 
            headerName: 'Group', 
            flex: 1,
            renderCell: (params) => (
                <Chip label={params.value} color="primary" variant="outlined" />
            )
        },
        { 
            field: 'lastLogin', 
            headerName: 'Last Login', 
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={new Date(params.value).toLocaleString()}>
                    <span>{new Date(params.value).toLocaleDateString()}</span>
                </Tooltip>
            )
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            flex: 1,
            renderCell: (params) => (
                <Chip 
                    label={params.value} 
                    color={params.value === 'Active' ? 'success' : 'error'}
                />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditUser(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleOpenDeleteDialog(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const rows = users.map((user) => ({
        id: user.id,
        fullName: `${user.first_name} ${user.last_name}`,
        email: user.email,
        company: '',
        group: user.groups.map((groupId) => {
            const group = groups.find((g) => g.id === groupId);
            return group ? group.name : 'Unknown';
        }).join(', '), // Join the group names into a single string
        lastLogin: user.last_login || 'Never',
        status: '',
        dateCreated: new Date(user.date_joined).toLocaleDateString(),
        cellNumber: '',
        emailVerified: '',
    }));

    return (
        <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ p: 3, mt: 7 }}>
                <Grid2 container spacing={3} alignItems="center" sx={{ mb: 3 }}>
                    <Grid2 item xs={12} md={6}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <MuiLink component={RouterLink} to="/" color="inherit">
                                Home
                            </MuiLink>
                            <MuiLink component={RouterLink} to="/dashboard" color="inherit">
                                Dashboard
                            </MuiLink>
                            <MuiLink component={RouterLink} to="/settings" color="inherit">
                                Settings
                            </MuiLink>
                            <Typography color="textPrimary">Manage Users</Typography>
                        </Breadcrumbs>
                        <Typography variant="h4" sx={{ mt: 2 }}>
                            Manage Users
                        </Typography>
                    </Grid2>
                    <Grid2 item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleAddUser}
                            startIcon={<PersonAddIcon />}
                        >
                            Add User
                        </Button>
                    </Grid2>
                </Grid2>

                <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                padding: 1,
                            },
                        }}
                    />
                </Paper>

                <Dialog open={open} onClose={handleCloseUserModal}>
                    <DialogTitle>{editMode ? 'Edit User' : 'Add User'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="First Name"
                            name="first_name"
                            value={currentUser.first_name}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Last Name"
                            name="last_name"
                            value={currentUser.last_name}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Username"
                            name="username"
                            value={currentUser.username}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={currentUser.email}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            value={currentUser.password}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="group-select-label">User Group</InputLabel>
                            <Select
                                labelId="group-select-label"
                                id="group-select"
                                name="group"
                                value={currentUser.group}
                                label="User Group"
                                onChange={handleInputChange}
                            >
                                {groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUserModal} color="error">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveUser} color="primary" variant="outlined">
                            {editMode ? 'Save Changes' : 'Create User'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete {userToDelete?.fullName}? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="error" variant="contained">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Paper>
    );
};

export default ManageUsers;