import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  useCreateNewDeviceMutation, 
  useLocalAddDevicesMutation, 
  useFetchVehiclesForGroupsQuery 
} from '../../Features/API/apiSlice';

const AddDevice = ({ open, onClose }) => {
  // State for one or more devices to add
  const [devices, setDevices] = useState([{ deviceId: '', deviceType: '' }]);
  // Option: "new" for creating a new vehicle or "existing" for assigning to an existing vehicle
  const [assignOption, setAssignOption] = useState('new');
  // Holds the selected existing vehicle’s id
  const [selectedExistingVehicle, setSelectedExistingVehicle] = useState('');
  
  const [createNewDevice, { isLoading: externalLoading }] = useCreateNewDeviceMutation();
  const [localAddDevices, { isLoading: localLoading }] = useLocalAddDevicesMutation();

  // Fetch local vehicles to populate the dropdown (expects vehiclesResponse.vehicles to be an array)
  const { data: vehiclesResponse } = useFetchVehiclesForGroupsQuery();

  const handleAddDeviceRow = () => {
    setDevices([...devices, { deviceId: '', deviceType: '' }]);
  };

  const handleRemoveDeviceRow = (index) => {
    setDevices(devices.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedDevices = [...devices];
    updatedDevices[index][field] = value;
    setDevices(updatedDevices);
  };

  const handleAddDevices = async () => {
    // Basic validation for device fields
    if (devices.some((device) => !device.deviceId || !device.deviceType)) {
      alert('Please fill out all fields for all devices.');
      return;
    }
    if (assignOption === 'existing' && !selectedExistingVehicle) {
      alert('Please select an existing vehicle.');
      return;
    }
  
    try {
      // Call the external API to create the device (this call remains unchanged)
      const externalResponses = await Promise.all(
        devices.map((device) =>
          createNewDevice({
            deviceId: device.deviceId,
            device_model: device.deviceType,
          }).unwrap()
        )
      );
  
      // Build payload for the local API
      const localPayload = externalResponses.map((response, index) => {
        const basePayload = {
          device_id: response.device_id,
          device_type: devices[index].deviceType,
          plate_number: devices[index].deviceId,
          name: devices[index].deviceId,
          imei: devices[index].deviceId,
          group_id: 7842, // Hardcoded group ID
        };
        // If assigning to an existing vehicle, include its id
        if (assignOption === 'existing') {
          return {
            ...basePayload,
            existing_vehicle_id: selectedExistingVehicle,
          };
        }
        return basePayload;
      });
  
      // Call the local API to add/synchronize the device(s)
      await localAddDevices(localPayload).unwrap();
  
      alert('Devices added and synchronized successfully!');
      onClose();
    } catch (error) {
      console.error(error);
      alert(`Failed to create devices: ${error.message}`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Devices</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {/* Option Selector */}
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={assignOption}
              onChange={(e) => setAssignOption(e.target.value)}
            >
              <FormControlLabel value="new" control={<Radio />} label="Create New Vehicle" />
              <FormControlLabel value="existing" control={<Radio />} label="Assign to Existing Vehicle" />
            </RadioGroup>
          </FormControl>

          {/* Searchable Autocomplete for existing vehicles */}
          {assignOption === 'existing' && (
            <Autocomplete
              options={vehiclesResponse?.vehicles || []}
              getOptionLabel={(option) => `${option.name} (${option.plate_number})`}
              value={
                vehiclesResponse?.vehicles?.find((vehicle) => vehicle.id === selectedExistingVehicle) || null
              }
              onChange={(event, newValue) => {
                setSelectedExistingVehicle(newValue ? newValue.id : '');
              }}
              renderInput={(params) => <TextField {...params} label="Select Vehicle" variant="outlined" />}
            />
          )}

          {/* Device Rows */}
          {devices.map((device, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                label="Device ID"
                value={device.deviceId}
                onChange={(e) => handleInputChange(index, 'deviceId', e.target.value)}
                fullWidth
                required
              />
              <FormControl fullWidth required>
                <InputLabel>Device Type</InputLabel>
                <Select
                  value={device.deviceType}
                  onChange={(e) => handleInputChange(index, 'deviceType', e.target.value)}
                >
                  <MenuItem value="FleetTrack">FleetTrack</MenuItem>
                  <MenuItem value="Teltonika">Teltonika</MenuItem>
                  <MenuItem value="FleetTrack Lite">FleetTrack Lite</MenuItem>
                  <MenuItem value="VVT">VVT</MenuItem>
                  <MenuItem value="VVT AI">VVT AI</MenuItem>
                </Select>
              </FormControl>
              <IconButton onClick={() => handleRemoveDeviceRow(index)} color="error" aria-label="remove device">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddDeviceRow}
            color="primary"
            variant="outlined"
          >
            Add Another Device
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button
          onClick={handleAddDevices}
          color="primary"
          variant="contained"
          disabled={externalLoading || localLoading}
        >
          {(externalLoading || localLoading) ? <CircularProgress size={24} /> : 'Create All'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDevice;
