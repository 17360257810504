import React from 'react';
import {
  Box,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  Paper,
  IconButton,
  Divider,
  Container,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ManageCard from './ManageCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DevicesIcon from '@mui/icons-material/Devices';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Grid2 from '@mui/material/Grid2'; // or '@mui/material/Grid2'

const SettingsPage = () => {
  const settingsCards = [
    {
      title: 'User Management',
      icon: <PeopleIcon sx={{ fontSize: 40, color: '#fff' }} />,
      manageLink: '/manage-users',
      groupLink: '/user-groups',
      manageTitle: 'Manage Users',
      groupTitle: 'Manage User Groups',
    },
    {
      title: 'Client Management',
      icon: <BusinessIcon sx={{ fontSize: 40, color: '#fff' }} />,
      manageLink: '/manage-clients',
      manageTitle: 'Manage Clients',
    },
    {
      title: 'Vehicle Management',
      icon: <DirectionsCarIcon sx={{ fontSize: 40, color: '#fff' }} />,
      manageLink: '/manage-vehicles',
      manageTitle: 'Manage Vehicles',
    },
    {
      title: 'Device Management',
      icon: <DevicesIcon sx={{ fontSize: 40, color: '#fff' }} />,
      manageLink: '/manage-devices',
      manageTitle: 'Manage Devices',
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: (theme) => theme.palette.background.default,
        py: { xs: 2, md: 4 }, // vertical padding
      }}
    >
      <Container maxWidth="lg">
        {/* Header / Hero Section */}
        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, md: 3 },
            mb: { xs: 3, md: 4 },
            borderRadius: 2,
            background: `linear-gradient(135deg, #ffffff, #f4f6f8)`,
          }}
        >
          {/* Breadcrumbs */}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRightIcon fontSize="small" />}
            sx={{
              mb: 3,
              '& a': { textDecoration: 'none', color: 'text.secondary' },
              '& a:hover': { color: 'primary.main', textDecoration: 'underline' },
            }}
          >
            <MuiLink
              component={RouterLink}
              to="/"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Home
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/dashboard"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <DashboardIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Dashboard
            </MuiLink>
            <Typography color="textPrimary" sx={{ display: 'flex', alignItems: 'center' }}>
              Settings
            </Typography>
          </Breadcrumbs>

          <Divider sx={{ mb: 3 }} />

          {/* Title + Subtitle */}
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton
              sx={{
                mr: 2,
                bgcolor: 'primary.light',
                color: '#fff',
                '&:hover': { bgcolor: 'primary.main' },
                borderRadius: '8px',
                fontSize: 32,
              }}
            >
              <AdminPanelSettingsOutlinedIcon fontSize="large" />
            </IconButton>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Montserrat, sans-serif',
                color: 'text.primary',
                // responsive font sizing
                fontSize: { xs: '1.8rem', md: '2.4rem' },
              }}
            >
              Admin Settings
            </Typography>
          </Box>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontFamily: 'Roboto, sans-serif' }}
          >
            Manage your system settings and configurations from this central dashboard.
          </Typography>
        </Paper>

        {/* Cards Section */}
        <Grid2
          container
          spacing={3}
          alignItems="stretch"
        >
          {settingsCards.map((card, index) => (
            <Grid2
              item
              key={index}
              xs={12}   // 1 column on mobile
              sm={6}    // 2 columns from 600px to <900px
              md={3}    // 4 columns from 900px and above
              display="flex"
              justifyContent="center"
            >
              <ManageCard
                title={card.title}
                icon={card.icon}
                manageLink={card.manageLink}
                groupLink={card.groupLink}
                manageTitle={card.manageTitle}
                groupTitle={card.groupTitle}
                sx={{
                  maxWidth: '350px',
                  width: '100%',      // let it fill the grid cell
                  // height: '200px', // If you want a fixed height
                }}
              />
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </Box>
  );
};

export default SettingsPage;
