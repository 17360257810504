import axios from 'axios';

const authInstance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,  // Use the auth URL from environment variables
});

// Function to reset password
export const resetPassword = async (username, email, newPassword) => {
    const response = await authInstance.post('/reset-password/', { username, email, newPassword });
    return response.data;
};
