import React, { useMemo } from 'react';
import Grid2 from '@mui/material/Grid2';
import { Card, Typography, Box, Paper, Skeleton } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MemoryIcon from '@mui/icons-material/Memory';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useFetchDeviceGroupsQuery } from '../../Features/API/apiSlice';
import CustomTreeView from './TreeViewComp';
import {
  BarChart,
  PieChart,
  ChartsXAxis,
  ChartsYAxis,
  ChartsTooltip,
  ChartsLegend,
} from '@mui/x-charts';
import WireFrameFuture from '../Photos/WireFrameFuture.png';

export default function BureauLandingPage() {
  const { data: groupsResponse, error, isLoading, refetch } = useFetchDeviceGroupsQuery();
  const groupList = groupsResponse?.groups || [];

  const groupCount = useMemo(() => groupList.length, [groupList]);
  const vehicleCount = useMemo(
    () => groupList.reduce((acc, g) => acc + g.vehicles.length, 0),
    [groupList]
  );
  const deviceCount = useMemo(() => {
    return groupList.reduce(
      (acc, g) => acc + g.vehicles.reduce((vAcc, v) => vAcc + v.devices.length, 0),
      0
    );
  }, [groupList]);

  const cards = [
    { title: 'Groups', count: groupCount, icon: <GroupsIcon fontSize="medium" />, iconColor: 'primary.main' },
    { title: 'Vehicles', count: vehicleCount, icon: <DirectionsCarIcon fontSize="medium" />, iconColor: 'success.main' },
    { title: 'Devices', count: deviceCount, icon: <MemoryIcon fontSize="medium" />, iconColor: 'warning.main' },
    { title: 'Agents Online', count: 12, icon: <PeopleOutlineIcon fontSize="medium" />, iconColor: 'secondary.main' },
    { title: 'Clients Online', count: 25, icon: <PersonOutlineIcon fontSize="medium" />, iconColor: 'info.main' },
    { title: 'Capacity Level', count: '55%', icon: <AssessmentIcon fontSize="medium" />, iconColor: 'success.main' },
  ];

  // Existing Chart Data
  const barChartData = [
    { category: 'Group A', value: 400, labelColor: '#FFFFFF' },
    { category: 'Group B', value: 300, labelColor: '#FFFFFF' },
  ];
  const pieChartData = [
    { id: 0, value: 400, label: 'Group A', labelColor: '#FFFFFF' },
    { id: 1, value: 300, label: 'Group B', labelColor: '#FFFFFF' },
  ];
  const pieColors = ['#0078B7', '#8FC74B'];

  // New Chart Data (Distinct)
  const newBarChartData = [
    { category: 'Zone 1', value: 250, labelColor: '#FFFFFF' },
    { category: 'Zone 2', value: 450, labelColor: '#FFFFFF' },
    { category: 'Zone 3', value: 150, labelColor: '#FFFFFF' },
  ];
  const newPieChartData = [
    { id: 0, value: 60, label: 'Active' },
    { id: 1, value: 30, label: 'Inactive' },
    { id: 2, value: 10, label: 'Pending' },
  ];
  const newPieColors = ['#FF5722', '#9C27B0', '#FFD700']; // Orange, Purple, Gold

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${WireFrameFuture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: -1,
          }}
        />
        <Grid2
          container
          sx={{
            position: 'relative',
            minHeight: '100vh',
            width: '100%',
            color: '#fff',
            overflowX: 'hidden',
            bgcolor: '#1a1a1a',
          }}
        >
          <Grid2
            size={{ xs: 12, sm: 6, md: 5, lg: 4 }}
            sx={{
              p: { xs: 2, sm: { top: 2, right: 2, bottom: 2, left: 0 } },
              maxHeight: '100vh',
              overflowY: 'auto',
              minWidth: { sm: '300px' },
              position: { sm: 'sticky' },
              top: 0,
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={400}
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 2, borderRadius: 2 }}
            />
            <Skeleton variant="text" width="80%" height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 1 }} />
            <Skeleton variant="text" width="60%" height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
          </Grid2>

          <Grid2
            size={{ xs: 12, sm: 6, md: 7, lg: 8 }}
            sx={{ p: { xs: 2, sm: 2 }, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
          >
            <Grid2 container spacing={1} sx={{ mb: 2, flexWrap: 'wrap' }}>
              {Array.from(new Array(6)).map((_, index) => (
                <Grid2 size={{ xs: 6, sm: 4, md: 4 }} key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={100}
                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 2 }}
                  />
                </Grid2>
              ))}
            </Grid2>

            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 2 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 2 }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </>
    );
  }

  if (error) return <Typography color="error">Error fetching data.</Typography>;

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${WireFrameFuture})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      />

      <Grid2
        container
        sx={{
          position: 'relative',
          minHeight: '100vh',
          width: '100%',
          color: '#fff',
          overflowX: 'hidden',
        }}
      >
        <Grid2
          size={{ xs: 12, sm: 6, md: 5, lg: 4 }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: { xs: 2, sm: { top: 2, right: 2, bottom: 2, left: 0 } },
            maxHeight: '100vh',
            overflowY: 'auto',
            minWidth: { sm: '300px' },
            position: { sm: 'sticky' },
            top: 0,
            zIndex: 1,
          }}
        >
          <CustomTreeView groups={groupList} onRefresh={refetch} />
        </Grid2>

        <Grid2
          size={{ xs: 12, sm: 6, md: 7, lg: 8 }}
          sx={{
            p: { xs: 2, sm: 2 },
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <Grid2 container spacing={1} sx={{ mb: 2, flexWrap: 'wrap' }}>
            {cards.map(({ title, count, icon, iconColor }) => (
              <Grid2 size={{ xs: 6, sm: 4, md: 4 }} key={title}>
                <Card
                  sx={{
                    borderRadius: 2,
                    boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                    backgroundColor: '#1a1a1a',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 1.5,
                    width: '100%',
                    minHeight: 100,
                  }}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      bgcolor: `${iconColor}33`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box sx={{ color: iconColor }}>{icon}</Box>
                  </Box>
                  <Box sx={{ textAlign: 'right', pr: 1 }}>
                    <Typography variant="caption" color="#ccc">
                      {title}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {count}
                    </Typography>
                  </Box>
                </Card>
              </Grid2>
            ))}
          </Grid2>

          <Grid2 container spacing={1}>
            {/* Existing BarChart */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Paper
                sx={{
                  borderRadius: 2,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                  backgroundColor: '#2a2a2a',
                  color: '#fff',
                  p: 1.5,
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Box>
                  <BarChart
                    width={Math.min(350, window.innerWidth * 0.6)}
                    height={300}
                    series={[{ data: barChartData.map((item) => item.value), label: 'Value', color: '#0078B7' }]}
                    xAxis={[{ scaleType: 'band', data: barChartData.map((item) => item.category) }]}
                    yAxis={[{ label: 'Value' }]}
                    margin={{ left: 50, right: 10, top: 30, bottom: 30 }}
                    slotProps={{
                      legend: { textStyle: { fill: '#fff', fontWeight: 'bold', fontSize: 10 } },
                      xAxis: { tickLabelStyle: { fill: '#fff' } },
                      yAxis: { tickLabelStyle: { fill: '#fff' }, labelStyle: { fill: '#fff' } },
                    }}
                  >
                    <ChartsXAxis />
                    <ChartsYAxis />
                    <ChartsTooltip />
                    <ChartsLegend />
                  </BarChart>
                </Box>
              </Paper>
            </Grid2>

            {/* Existing PieChart */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Paper
                sx={{
                  borderRadius: 2,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                  backgroundColor: '#2a2a2a',
                  color: '#fff',
                  p: 1.5,
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Box>
                  <PieChart
                    width={Math.min(350, window.innerWidth * 0.6)}
                    height={300}
                    series={[
                      {
                        data: pieChartData,
                        innerRadius: 50,
                        outerRadius: 100,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        cx: '50%',
                        cy: '50%',
                      },
                    ]}
                    colors={pieColors}
                    slotProps={{
                      legend: { textStyle: { fill: '#fff', fontWeight: 'bold', fontSize: 10 } },
                    }}
                  >
                    <ChartsLegend />
                  </PieChart>
                </Box>
              </Paper>
            </Grid2>

            {/* New BarChart */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Paper
                sx={{
                  borderRadius: 2,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                  backgroundColor: '#2a2a2a',
                  color: '#fff',
                  p: 1.5,
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Box>
                  <BarChart
                    width={Math.min(350, window.innerWidth * 0.6)}
                    height={300}
                    series={[
                      {
                        data: newBarChartData.map((item) => item.value),
                        label: 'Zones',
                        color: '#FF5722', // Orange for distinction
                        type: 'bar',
                        barWidth: 30, // Thinner bars
                      },
                    ]}
                    xAxis={[{ scaleType: 'band', data: newBarChartData.map((item) => item.category) }]}
                    yAxis={[{ label: 'Count' }]}
                    margin={{ left: 50, right: 10, top: 30, bottom: 30 }}
                    slotProps={{
                      legend: { textStyle: { fill: '#fff', fontWeight: 'bold', fontSize: 10 } },
                      xAxis: { tickLabelStyle: { fill: '#fff' } },
                      yAxis: { tickLabelStyle: { fill: '#fff' }, labelStyle: { fill: '#fff' } },
                    }}
                  >
                    <ChartsXAxis />
                    <ChartsYAxis />
                    <ChartsTooltip />
                    <ChartsLegend />
                  </BarChart>
                </Box>
              </Paper>
            </Grid2>

            {/* New PieChart */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Paper
                sx={{
                  borderRadius: 2,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.4)',
                  backgroundColor: '#2a2a2a',
                  color: '#fff',
                  p: 1.5,
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Box>
                  <PieChart
                    width={Math.min(350, window.innerWidth * 0.6)}
                    height={300}
                    series={[
                      {
                        data: newPieChartData,
                        innerRadius: 0, // No inner radius for a full pie
                        outerRadius: 120, // Larger pie
                        paddingAngle: 2, // Smaller padding
                        cornerRadius: 0, // No rounding
                        cx: '50%',
                        cy: '50%',
                      },
                    ]}
                    colors={newPieColors}
                    slotProps={{
                      legend: { textStyle: { fill: '#fff', fontWeight: 'bold', fontSize: 10 } },
                    }}
                  >
                    <ChartsLegend />
                  </PieChart>
                </Box>
              </Paper>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
}