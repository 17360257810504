import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Grid,
  Checkbox,
  ListSubheader,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  useUpdateVehicleMutation,
  useUpdateDeviceMutation,
  useCreateNewInstallTestMutation,
  useFetchBranchesQuery,
  useFetchTechniciansQuery,
} from '../../Features/API/apiSlice';


const steps = [
  'Company Info',
  'Vehicle Details',
  'Device Details',
  'Checklist',
  'Summary',
  'Reference Number',
];

const NewInstallWizard = ({
  groups,
  selectedCompany,
  selectedVehicle,
  selectedDevice,
  selectedGroupId,
  open,
  onClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCompanyState, setSelectedCompanyState] = useState('');
  const [formData, setFormData] = useState({
    simType: '',
  });
  const [vehicles, setVehicles] = useState([]);
  const [ioLines, setIoLines] = useState([{ ioNumber: '', ioDescription: '' }]);

  // We'll store the final reference number from the backend here
  const [finalRefNumber, setFinalRefNumber] = useState('');

  const [updateVehicle] = useUpdateVehicleMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [createNewInstallTest, { isLoading: isSaving, error: saveError }] =
    useCreateNewInstallTestMutation();

  // 1) Fetch all branches
  const {
    data: branches,
    isLoading: branchesLoading,
    isError: branchesError,
  } = useFetchBranchesQuery();

  // 2) Fetch technicians, optionally filtered by selected branchId
  const {
    data: technicians,
    isLoading: techLoading,
    isError: techError,
  } = useFetchTechniciansQuery(formData.branchId || null);

  // Initialize the company name
  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanyState(selectedCompany);
    }
  }, [selectedCompany]);

  // Initialize the device type in formData
  useEffect(() => {
    if (selectedDevice) {
      setFormData((prevData) => ({
        ...prevData,
        deviceType: selectedDevice.device_type,
      }));
    }
  }, [selectedDevice]);

  // Initialize the selected device if there's only one device
  useEffect(() => {
    console.log('Selected Vehicle in Wizard:', selectedVehicle);
    if (selectedVehicle?.devices?.length === 1) {
      const [defaultDevice] = selectedVehicle.devices;
      console.log('Auto-selecting device:', defaultDevice);
      setFormData((prevData) => ({
        ...prevData,
        selectedDeviceId: defaultDevice.device_id, // Ensure correct field
        deviceType: defaultDevice.device_type,
      }));
    }
  }, [selectedVehicle]);

  const handleNext = async () => {
    // Step 2 => update local vehicle & external API device
    if (activeStep === 2) {
      if (!formData.selectedDeviceId) {
        console.error('No device selected. Please ensure a device is selected before proceeding.');
        return; // Prevent proceeding if no device is selected
      }

      try {
        // 1) Update vehicle in local DB
        const vehiclePayload = {
          id: selectedVehicle.id,
          plate_number: formData.plateNumber,
          make: formData.make,
          model: formData.model,
          colour: formData.colour,
          group_id: selectedGroupId,
          name: formData.plateNumber, // Update name field using the plate number
        };
        const vehicleResponse = await updateVehicle(vehiclePayload).unwrap();
        console.log('Vehicle updated locally:', vehicleResponse);

        // 2) Update device externally
        const devicePayload = {
          device_id: formData.selectedDeviceId,
          plate_number: formData.plateNumber,
          make: formData.make,
          model: formData.model,
        };
        const deviceResponse = await updateDevice(devicePayload).unwrap();
        console.log('Device updated on external API:', deviceResponse);
      } catch (error) {
        console.error('Failed to update vehicle or device:', error);
      }
    }

    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // Generic input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // If name === 'channelNumber' ...
    if (name === 'channelNumber' && (value < 1 || value > 9)) {
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddVehicle = () => {
    setVehicles([...vehicles, { plateNumber: '', make: '', model: '' }]);
  };

  const handleVehicleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedVehicles = vehicles.map((vehicle, i) =>
      i === index ? { ...vehicle, [name]: value } : vehicle
    );
    setVehicles(updatedVehicles);
  };

  // Add new I/O line
  const handleAddIoLine = () => {
    setIoLines([...ioLines, { ioNumber: '', ioDescription: '' }]);
  };

  // Update an I/O line
  const handleIoLineChange = (index, event) => {
    const { name, value } = event.target;
    const updatedIoLines = ioLines.map((line, i) =>
      i === index ? { ...line, [name]: value } : line
    );
    setIoLines(updatedIoLines);
  };

  // Utility to show "Yes"/"No"
  const yesOrNo = (val) => {
    if (val === true) return 'Yes';
    if (val === false) return 'No';
    return 'N/A';
  };

  // =============== SAVE LOGIC (Approve/Fail) ===============
  // If user clicks "Approve" or "Fail", we do one POST => new-install-test
  const handleSaveTest = async (testStatus) => {
    try {
      // 1) Build the full payload for new-install-test
      const payload = {
        vehicle: {
          plate_number: formData.plateNumber,
          group_id: selectedGroupId,
          name: formData.plateNumber || 'Unnamed Vehicle',
          make: formData.make,
          model: formData.model,
          colour: formData.colour,
        },
        device: {
          device_id: formData.selectedDeviceId,
          device_type: formData.deviceType,
          sim_type: formData.simType || '',
          channel_number: formData.channelNumber || '',
          tracking_number: formData.trackingNumber || '',
          sim_serial_number: formData.simSerialNumber || '',
        },
        install_test: {
          technician: formData.technicianId ? Number(formData.technicianId) : null,
          branch: formData.branchId ? Number(formData.branchId) : null,
          odo_reading: Number(formData.odoReading) || 0,
          special_notes: formData.specialNotes || '',
          status: testStatus,
          // Checklist fields
          gps_min_satellites: !!formData.gpsMinSatellites,
          ai_config: !!formData.aiConfig,
          device_storage_connected:
            formData.deviceStorageConnected === undefined
              ? null
              : !!formData.deviceStorageConnected,
          device_storage_recording:
            formData.deviceStorageRecording === undefined
              ? null
              : !!formData.deviceStorageRecording,
          all_camera_angles:
            formData.allCameraAngles === undefined
              ? null
              : !!formData.allCameraAngles,
          cameras_visible_clean:
            formData.camerasVisibleClean === undefined
              ? null
              : !!formData.camerasVisibleClean,
          cameras_recording:
            formData.camerasRecording === undefined
              ? null
              : !!formData.camerasRecording,
          cameras_audio:
            formData.camerasAudio === undefined
              ? null
              : !!formData.camerasAudio,
          dsm_configured:
            formData.dsmConfigured === undefined
              ? null
              : !!formData.dsmConfigured,
          adas_configured:
            formData.adasConfigured === undefined
              ? null
              : !!formData.adasConfigured,
          io_lines: ioLines.map((line, index) => ({
            io_number: line.ioNumber,
            io_description: line.ioDescription,
            io_checked: !!formData[`ioChecked_${index}`],
          })),
        },
      };
  
      // 2) POST to new-install-test
      const response = await createNewInstallTest(payload).unwrap();
      console.log('Install test created => ', response);
  
      // 3) Grab final reference_number from response
      const refNo = response.data.reference_number || 'N/A';
      setFinalRefNumber(refNo);
  
      // Move wizard to final step
      setActiveStep(5);
    } catch (err) {
      console.error('Failed to create install test:', err);
      // Optionally handle error UI
    }
  };

  // The final "Finish" just closes the wizard
  const handleFinish = () => {
    onClose();
  };

  console.log('Selected Device ID:', selectedDevice?.id);

  // ============ JSX RENDER ================
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        New Install Wizard
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              mb: 4,
              backgroundColor: '#f5f5f5',
              padding: 2,
              borderRadius: 2,
            }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      color:
                        activeStep === index ? 'primary.main' : 'text.secondary',
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mt: 2, mb: 1, p: 2 }}>
            {/* Step 0: Company Info */}
            {activeStep === 0 && (
              <Typography variant="body1">
                <strong>Company:</strong> {selectedCompanyState}
              </Typography>
            )}

            {/* Step 1: Vehicle Details */}
            {activeStep === 1 && (
              <Box>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Make"
                      name="make"
                      value={formData.make || ''}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Model"
                      name="model"
                      value={formData.model || ''}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Colour"
                      name="colour"
                      value={formData.colour || ''}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Vehicle Registration/Plate Number"
                      name="plateNumber"
                      value={formData.plateNumber || ''}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Step 2: Device Details */}
            {activeStep === 2 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  {selectedVehicle?.devices?.length > 1
                    ? 'Select Device for Installation'
                    : 'Device Selected for Installation'}
                </Typography>

                {selectedVehicle?.devices?.length === 1 ? (
                  // Single Device Case
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Device:</strong> {selectedVehicle.devices[0].device_type}
                    </Typography>
                    <Typography variant="body2">
                      <strong>IMEI:</strong> {selectedVehicle.devices[0].imei}
                    </Typography>
                  </Box>
                ) : (
                  // Multiple Devices Case
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Device</InputLabel>
                    <Select
                      name="selectedDeviceId"
                      value={formData.selectedDeviceId || ''}
                      onChange={(e) => {
                        const selectedDeviceId = e.target.value;
                        const selectedDev = selectedVehicle.devices.find(
                          (d) => d.device_id === selectedDeviceId
                        );
                        console.log('Selected Device:', selectedDev);
                        setFormData((prevData) => ({
                          ...prevData,
                          selectedDeviceId,
                          deviceType: selectedDev?.device_type || '',
                        }));
                      }}
                    >
                      {selectedVehicle?.devices?.map((device) => (
                        <MenuItem key={device.device_id} value={device.device_id}>
                          {device.device_type} - {device.imei}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {formData.deviceType && (
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    <strong>Device Type:</strong> {formData.deviceType}
                  </Typography>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Sim Type</InputLabel>
                    <Select
                      name="simType"
                      value={formData.simType || ''}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          simType: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="Global Sim">Global Sim</MenuItem>
                      <MenuItem value="Normal Sim">Normal Sim</MenuItem>
                      <MenuItem value="APN Sim">APN Sim</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                {/* VVT or VVT AI => show channelNumber */}
                {['vvt', 'vvt ai'].includes(
                  (formData.deviceType || '').toLowerCase()
                ) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                      fullWidth
                      label="Channel Number"
                      name="channelNumber"
                      type="number"
                      inputProps={{ min: 0, max: 9 }}
                      value={formData.channelNumber || ''}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val === '' || (/^\d$/.test(val) && val >= 0 && val <= 9)) {
                          handleInputChange(e);
                        }
                      }}
                    />
                    <Button
                      onClick={() =>
                        setFormData((prevData) => ({
                          ...prevData,
                          channelNumber: '',
                        }))
                      }
                      sx={{ ml: 2 }}
                    >
                      Clear
                    </Button>
                  </Box>
                )}

                {/* I/O lines */}
                {ioLines.map((line, index) => (
                  <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="I/O Number"
                        name="ioNumber"
                        type="number"
                        inputProps={{ min: 1, max: 15 }}
                        value={line.ioNumber || ''}
                        onChange={(e) => handleIoLineChange(index, e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>I/O Description</InputLabel>
                        <Select
                          name="ioDescription"
                          value={line.ioDescription || ''}
                          onChange={(e) => handleIoLineChange(index, e)}
                        >
                          <MenuItem value="Panic Button">Panic Button</MenuItem>
                          <MenuItem value="PTO">PTO</MenuItem>
                          <MenuItem value="Passenger Door Switch">
                            Passenger Door Switch
                          </MenuItem>
                          <MenuItem value="Driver door switch">
                            Driver door switch
                          </MenuItem>
                          <MenuItem value="Cargo door switch">
                            Cargo door switch
                          </MenuItem>
                          <MenuItem value="Seatbelt">Seatbelt</MenuItem>
                          <MenuItem value="TipLock">TipLock</MenuItem>
                          <MenuItem value="Canbus">Canbus</MenuItem>
                          <MenuItem value="Security Box">Security Box</MenuItem>
                          <MenuItem value="Left Indicator">Left Indicator</MenuItem>
                          <MenuItem value="Right Indicator">Right Indicator</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddIoLine}
                  sx={{ mb: 2 }}
                >
                  Add I/O Line
                </Button>

                <TextField
                  fullWidth
                  label="Tracking Number"
                  name="trackingNumber"
                  value={formData.trackingNumber || ''}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Sim Serial Number"
                  name="simSerialNumber"
                  value={formData.simSerialNumber || ''}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />

                {/* --------------- DYNAMIC BRANCH DROPDOWN --------------- */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Select Branch
                  </Typography>
                  {branchesLoading && <p>Loading branches...</p>}
                  {branchesError && <p>Error fetching branches!</p>}
                  {!branchesLoading && !branchesError && (
                    <FormControl fullWidth>
                      <InputLabel>Branch</InputLabel>
                      <Select
                        name="branchId"
                        value={formData.branchId || ''}
                        onChange={(e) => {
                          const branchId = e.target.value;
                          const branchObj = branches?.find((b) => b.id === branchId);
                          const branchName = branchObj ? branchObj.name : '';
                          console.log('Selected Branch ID:', branchId);
                          setFormData((prev) => ({
                            ...prev,
                            branchId,
                            branchName,
                            // also reset technician if changing branch
                            technicianId: '',
                            technicianName: '',
                          }));
                        }}
                      >
                        {branches?.map((b) => (
                          <MenuItem key={b.id} value={b.id}>
                            {b.name} ({b.branch_type})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                {/* --------------- DYNAMIC TECHNICIAN DROPDOWN --------------- */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Select Technician
                  </Typography>
                  {techLoading && <p>Loading technicians...</p>}
                  {techError && <p>Error fetching technicians!</p>}
                  {!techLoading && !techError && (
                    <FormControl fullWidth>
                      <InputLabel>Technician</InputLabel>
                      <Select
                        name="technicianId"
                        value={formData.technicianId || ''}
                        onChange={(e) => {
                          const techId = e.target.value;
                          // find the technician object
                          const techObj = technicians?.find((t) => t.id === techId);
                          const techName = techObj
                            ? `${techObj.first_name} ${techObj.last_name}`
                            : '';
                          setFormData((prev) => ({
                            ...prev,
                            technicianId: techId,
                            technicianName: techName,
                          }));
                        }}
                      >
                        {technicians?.map((t) => (
                          <MenuItem key={t.id} value={t.id}>
                            {t.first_name} {t.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                {/* ODO + Special Notes */}
                <TextField
                  fullWidth
                  label="ODO Reading"
                  name="odoReading"
                  value={formData.odoReading || ''}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Special Notes"
                  name="specialNotes"
                  value={formData.specialNotes || ''}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
              </Box>
            )}

            {/* Step 3: Checklist */}
            {activeStep === 3 && (
              <>
                {/* Teltonika / FleetTrack / Lite */}
                {(() => {
                  const lower = (formData.deviceType || '').toLowerCase();
                  if (
                    lower.includes('teltonika') ||
                    lower.includes('fleettrack') ||
                    lower.includes('lite')
                  ) {
                    return (
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Checklist
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          GPS
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>
                            {['vvt', 'vvt ai'].includes(lower)
                              ? 'Min 8 Satellites'
                              : 'Min 7 Satellites'}
                          </InputLabel>
                          <Select
                            name="gpsMinSatellites"
                            value={formData.gpsMinSatellites || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>

                        {/* Show AI Config only if device type is NOT Teltonika or FleetTrack */}
                        {!(lower === 'teltonika' || lower === 'fleettrack' ||
                           lower === 'teltonika/fleettrack' || lower === 'fleettrack/teltonika') && (
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>AI Config</InputLabel>
                            <Select
                              name="aiConfig"
                              value={formData.aiConfig || ''}
                              onChange={handleInputChange}
                            >
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        )}

                        {/* Internal Battery Checklist Item */}
                        {(lower === 'teltonika' || lower === 'fleettrack' ||
                          lower === 'teltonika/fleettrack') && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData.internalBattery || false}
                                onChange={(e) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    internalBattery: e.target.checked,
                                  }))
                                }
                                name="internalBattery"
                              />
                            }
                            label="Internal Battery"
                          />
                        )}
                      </Box>
                    );
                  }
                  return null;
                })()}

                {/* Render I/O checklist items */}
                {ioLines.length > 0 && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      I/O Checklist
                    </Typography>
                    {ioLines.map((line, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={formData[`ioChecked_${index}`] || false}
                            onChange={(e) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                [`ioChecked_${index}`]: e.target.checked,
                              }))
                            }
                            name={`ioChecked_${index}`}
                          />
                        }
                        label={`I/O #${line.ioNumber || '?'} - ${line.ioDescription || 'N/A'}`}
                      />
                    ))}
                  </Box>
                )}

                {/* VVT */}
                {(() => {
                  if ((formData.deviceType || '').toLowerCase() === 'vvt') {
                    return (
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Checklist
                        </Typography>
                        {/* Device Storage */}
                        <Typography variant="subtitle1" gutterBottom>
                          Device Storage
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Connected</InputLabel>
                          <Select
                            name="deviceStorageConnected"
                            value={formData.deviceStorageConnected || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Recording</InputLabel>
                          <Select
                            name="deviceStorageRecording"
                            value={formData.deviceStorageRecording || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        {/* GPS */}
                        <Typography variant="subtitle1" gutterBottom>
                          GPS
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>
                            {['vvt', 'vvt ai'].includes((formData.deviceType || '').toLowerCase())
                              ? 'Min 8 Satellites'
                              : 'Min 7 Satellites'}
                          </InputLabel>
                          <Select
                            name="gpsMinSatellites"
                            value={formData.gpsMinSatellites || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>

                        {/* Cameras */}
                        <Typography variant="subtitle1" gutterBottom>
                          Cameras
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All Camera Angles</InputLabel>
                          <Select
                            name="allCameraAngles"
                            value={formData.allCameraAngles || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras clearly visible and clean</InputLabel>
                          <Select
                            name="camerasVisibleClean"
                            value={formData.camerasVisibleClean || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras recording</InputLabel>
                          <Select
                            name="camerasRecording"
                            value={formData.camerasRecording || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras audio working</InputLabel>
                          <Select
                            name="camerasAudio"
                            value={formData.camerasAudio || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    );
                  }
                  return null;
                })()}

                {/* VVT AI */}
                {(() => {
                  if ((formData.deviceType || '').toLowerCase() === 'vvt ai') {
                    return (
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Checklist
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Device Storage
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Connected</InputLabel>
                          <Select
                            name="deviceStorageConnected"
                            value={formData.deviceStorageConnected || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Recording</InputLabel>
                          <Select
                            name="deviceStorageRecording"
                            value={formData.deviceStorageRecording || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>

                        <Typography variant="subtitle1" gutterBottom>
                          GPS
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>
                            {['vvt', 'vvt ai'].includes((formData.deviceType || '').toLowerCase())
                              ? 'Min 8 Satellites'
                              : 'Min 7 Satellites'}
                          </InputLabel>
                          <Select
                            name="gpsMinSatellites"
                            value={formData.gpsMinSatellites || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>

                        <Typography variant="subtitle1" gutterBottom>
                          Cameras
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All Camera Angles</InputLabel>
                          <Select
                            name="allCameraAngles"
                            value={formData.allCameraAngles || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras clearly visible and clean</InputLabel>
                          <Select
                            name="camerasVisibleClean"
                            value={formData.camerasVisibleClean || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras recording</InputLabel>
                          <Select
                            name="camerasRecording"
                            value={formData.camerasRecording || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>All cameras audio working</InputLabel>
                          <Select
                            name="camerasAudio"
                            value={formData.camerasAudio || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>

                        <Typography variant="subtitle1" gutterBottom>
                          Configurations
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>DSM Configured</InputLabel>
                          <Select
                            name="dsmConfigured"
                            value={formData.dsmConfigured || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>ADAS Configured</InputLabel>
                          <Select
                            name="adasConfigured"
                            value={formData.adasConfigured || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    );
                  }
                  return null;
                })()}
              </>
            )}

            {/* Step 4: Summary */}
            {activeStep === 4 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Summary
                </Typography>

                <Typography variant="subtitle1">Vehicle Info</Typography>
                <Typography variant="body2">
                  Make: {formData.make || '-'}
                </Typography>
                <Typography variant="body2">
                  Model: {formData.model || '-'}
                </Typography>
                <Typography variant="body2">
                  Colour: {formData.colour || '-'}
                </Typography>
                <Typography variant="body2">
                  Plate Number: {formData.plateNumber || '-'}
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Device Info
                </Typography>
                <Typography variant="body2">
                  Device Type: {formData.deviceType || '-'}
                </Typography>
                <Typography variant="body2">
                  Sim Type: {formData.simType || '-'}
                </Typography>
                <Typography variant="body2">
                  Tracking Number: {formData.trackingNumber || '-'}
                </Typography>
                <Typography variant="body2">
                  Sim Serial Number: {formData.simSerialNumber || '-'}
                </Typography>

                {/* Show chosen Branch/Technician */}
                <Typography variant="body2">
                  Branch Name: {formData.branchName || '-'}
                </Typography>
                <Typography variant="body2">
                  Technician Name: {formData.technicianName || '-'}
                </Typography>

                <Typography variant="body2">
                  ODO Reading: {formData.odoReading || '-'}
                </Typography>
                <Typography variant="body2">
                  Special Notes: {formData.specialNotes || '-'}
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  I/O Lines
                </Typography>
                {ioLines.length > 0 ? (
                  ioLines.map((line, index) => (
                    <Typography key={index} variant="body2">
                      {`I/O #${line.ioNumber || '?'} - ${line.ioDescription || 'N/A'}`}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2">No I/O lines added.</Typography>
                )}

                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Checklist
                </Typography>
                <Typography variant="body2">
                  Min 7 Satellites: {yesOrNo(formData.gpsMinSatellites)}
                </Typography>
                <Typography variant="body2">
                  AI Config: {yesOrNo(formData.aiConfig)}
                </Typography>
                <Typography variant="body2">
                  Device Storage Connected: {yesOrNo(formData.deviceStorageConnected)}
                </Typography>
                <Typography variant="body2">
                  Device Storage Recording: {yesOrNo(formData.deviceStorageRecording)}
                </Typography>
                <Typography variant="body2">
                  All Camera Angles: {yesOrNo(formData.allCameraAngles)}
                </Typography>
                <Typography variant="body2">
                  All cameras clearly visible: {yesOrNo(formData.camerasVisibleClean)}
                </Typography>
                <Typography variant="body2">
                  All cameras recording: {yesOrNo(formData.camerasRecording)}
                </Typography>
                <Typography variant="body2">
                  All cameras audio working: {yesOrNo(formData.camerasAudio)}
                </Typography>
                <Typography variant="body2">
                  DSM Configured: {yesOrNo(formData.dsmConfigured)}
                </Typography>
                <Typography variant="body2">
                  ADAS Configured: {yesOrNo(formData.adasConfigured)}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleSaveTest('failed')}
                  >
                    Fail
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveTest('approved')}
                  >
                    Approve
                  </Button>
                </Box>
              </Box>
            )}

            {/* Step 5: Reference Number */}
            {activeStep === 5 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Reference Number
                </Typography>
                <Typography variant="body1">
                  Your reference number is: {finalRefNumber || '...'}
                </Typography>
                <Button variant="contained" color="primary" onClick={handleFinish}>
                  Finish
                </Button>
              </Box>
            )}

            {/* Next/Back for steps < 4 */}
            {activeStep < 4 && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewInstallWizard;
