import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  useAssignDeviceToGroupMutation,
  useCreateNewDeviceMutation,
  useCreateNewRepairTestMutation,
  useCreateDeviceSameGroupMutation,  // in case you use a dynamic group approach
} from '../../Features/API/apiSlice';

const steps = [
  'Unit Swap',
  'Add New Device',
  'Comments',
  'Checklist',
  'Summary',
  'Reference Number'
];

const RepairWizard = ({ open, onClose, selectedCompany, selectedDevice }) => {
  const [activeStep, setActiveStep] = useState(0);

  // All the fields from your wizard
  const [formData, setFormData] = useState({
    isUnitSwap: 'No',
    newDeviceId: '',
    repairComments: '',
    repairReasons: '',
    deviceStorageConnected: false,
    deviceStorageRecording: false,
    gpsMinSatellites: false,
    camerasAngles: false,
    camerasVisible: false,
    camerasRecording: false,
    camerasAudio: false,
    dsmConfigured: false,
    adasConfigured: false,
    peripherals: {
      panicButton: false,
      passengerDoorSwitch: false,
      driverDoorSwitch: false,
      cargoDoorSwitch: false,
      seatbelt: false,
      tipLock: false,
      leftIndicator: false,
      rightIndicator: false,
      securityBox: false,
    },
  });

  // This will store the backend-generated reference number
  const [finalRefNumber, setFinalRefNumber] = useState('');

  // For confirming "Unit Swap"
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // RTK Query hooks
  const [assignDeviceToGroup] = useAssignDeviceToGroupMutation();
  const [createNewDevice, { isLoading: isCreating }] = useCreateNewDeviceMutation();
  const [createDeviceSameGroup, { isLoading: isCreatingSameGroup }] =
    useCreateDeviceSameGroupMutation(); // only if you have a "create same group" approach
  const [createNewRepairTest, { isLoading: isSaving, error: saveError }] =
    useCreateNewRepairTestMutation();

  //---------------------------------------
  // Handlers
  //---------------------------------------

  /**
   * Step 1: "Add New Device"
   * If user selected "Yes" for Unit Swap => create device in old device's group
   * THEN move old device to "swap" group.
   */
  const handleAddNewDevice = async () => {
    if (!formData.newDeviceId.trim()) {
      alert('Please enter a valid Device ID.');
      return;
    }

    if (formData.isUnitSwap === 'Yes') {
      // === Create the new device first ===
      try {
        // If you have a specialized approach with createDeviceSameGroup:
        //   pass in old_device_id, new_device_id, etc.
        // OR just pass deviceId if your "createNewDevice" has been upgraded to accept a dynamic group.
        // For this example, let's assume we use createDeviceSameGroup for the old group logic:
        const oldDeviceId = selectedDevice?.device_id || selectedDevice?.id;
        if (!oldDeviceId) {
          alert('No oldDeviceId found.');
          return;
        }

        const payload = {
          old_device_id: String(oldDeviceId),
          new_device_id: formData.newDeviceId.trim(),
          new_device_model: selectedDevice?.device_type || 'DefaultModel',
        };
        console.log('Creating device in old group => payload:', payload);

        const response = await createDeviceSameGroup(payload).unwrap();
        console.log('New device created in old group:', response);
        alert(`Created new device in old group => ${response?.message || ''}`);

      } catch (error) {
        console.error('Failed to create device in old group:', error);
        alert('Error creating device in old group. Please try again.');
        return; // Stop here if creation failed
      }

      // === Now move the old device to the "swap" group ===
      try {
        // If your "swap" group move is done by `assignDeviceToGroup` with a special payload
        // e.g. { device_id: oldDeviceId, new_group_id: 9999 } => depends on your design
        const oldPayload = { device_id: selectedDevice.device_id };
        console.log('Now moving old device =>', oldPayload);
        await assignDeviceToGroup(oldPayload).unwrap();

        // Done: old device is in "spare" group, new device is in the original group
        alert('Old device moved to spare group successfully.');
      } catch (err) {
        console.error('Failed to move old device to spare group:', err);
        alert('Error moving old device. Please check logs.');
      }

      // Now proceed in the wizard
      handleNext();
    } else {
      // If user selected "No" => normal createNewDevice logic or skip entirely
      try {
        const payload = {
          deviceId: formData.newDeviceId.trim(),
          device_model: selectedDevice?.device_type || 'DefaultModel',
        };

        console.log('Creating new device with payload:', payload);
        const response = await createNewDevice(payload).unwrap();
        console.log('Device created successfully:', response);

        alert(`Device ${response.device_id} created successfully.`);
        handleNext();
      } catch (error) {
        console.error('Failed to create new device:', error);
        alert('Error creating new device. Please try again.');
      }
    }
  };

  // Called whenever user changes a form field
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    // If user selects "Yes" for isUnitSwap => show confirm
    if (name === 'isUnitSwap' && value === 'Yes') {
      setConfirmModalOpen(true);
    }

    // If it's a checkbox, store boolean. Otherwise store string
    if (name in formData.peripherals) {
      setFormData((prevData) => ({
        ...prevData,
        peripherals: {
          ...prevData.peripherals,
          [name]: type === 'checkbox' ? checked : value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // If user said "No" => skip "Add New Device" step
      if (prevActiveStep === 0 && formData.isUnitSwap === 'No') {
        return prevActiveStep + 2;
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // If user confirms "Proceed" in the modal => just close the modal & do nothing
  // We'll do the "move old device" AFTER we create the new device
  const handleConfirmProceed = () => {
    setConfirmModalOpen(false);
  };

  // If user cancels => revert to "No"
  const handleConfirmCancel = () => {
    setConfirmModalOpen(false);
    setFormData((prevData) => ({
      ...prevData,
      isUnitSwap: 'No',
    }));
  };

  // Step 4 => user clicks "Pass"
  const handlePass = async () => {
    try {
      const payload = {
        device: { device_id: selectedDevice?.device_id || selectedDevice?.id },
        repair_test: {
          status: 'approved',
          repair_comments: formData.repairComments,
          repair_reasons: formData.repairReasons,
          device_storage_connected: formData.deviceStorageConnected,
          device_storage_recording: formData.deviceStorageRecording,
          gps_min_satellites: formData.gpsMinSatellites,
          cameras_angles: formData.camerasAngles,
          cameras_visible: formData.camerasVisible,
          cameras_recording: formData.camerasRecording,
          cameras_audio: formData.camerasAudio,
          dsm_configured: formData.dsmConfigured,
          adas_configured: formData.adasConfigured,
          panic_button: formData.peripherals.panicButton,
          passenger_door_switch: formData.peripherals.passengerDoorSwitch,
          driver_door_switch: formData.peripherals.driverDoorSwitch,
          cargo_door_switch: formData.peripherals.cargoDoorSwitch,
          seatbelt: formData.peripherals.seatbelt,
          tip_lock: formData.peripherals.tipLock,
          left_indicator: formData.peripherals.leftIndicator,
          right_indicator: formData.peripherals.rightIndicator,
          security_box: formData.peripherals.securityBox,
        },
      };
      const response = await createNewRepairTest(payload).unwrap();
      const refNo = response.data?.reference_number || 'N/A';
      setFinalRefNumber(refNo);
      setActiveStep(5);
    } catch (err) {
      console.error('Failed to create repair test:', err);
      alert('Error creating repair test');
    }
  };

  // Step 4 => user clicks "Fail"
  const handleFail = async () => {
    try {
      const payload = {
        device: { device_id: selectedDevice?.id },
        repair_test: {
          status: 'failed',
          repair_comments: formData.repairComments,
          repair_reasons: formData.repairReasons,
          device_storage_connected: formData.deviceStorageConnected,
          device_storage_recording: formData.deviceStorageRecording,
          gps_min_satellites: formData.gpsMinSatellites,
          cameras_angles: formData.camerasAngles,
          cameras_visible: formData.camerasVisible,
          cameras_recording: formData.camerasRecording,
          cameras_audio: formData.camerasAudio,
          dsm_configured: formData.dsmConfigured,
          adas_configured: formData.adasConfigured,
          panic_button: formData.peripherals.panicButton,
          passenger_door_switch: formData.peripherals.passengerDoorSwitch,
          driver_door_switch: formData.peripherals.driverDoorSwitch,
          cargo_door_switch: formData.peripherals.cargoDoorSwitch,
          seatbelt: formData.peripherals.seatbelt,
          tip_lock: formData.peripherals.tipLock,
          left_indicator: formData.peripherals.leftIndicator,
          right_indicator: formData.peripherals.rightIndicator,
          security_box: formData.peripherals.securityBox,
        },
      };

      const response = await createNewRepairTest(payload).unwrap();
      const refNo = response.data?.reference_number || 'N/A';
      setFinalRefNumber(refNo);
      setActiveStep(5);
    } catch (err) {
      console.error('Failed to create repair test:', err);
      alert('Error creating repair test');
    }
  };

  // Final step => close wizard
  const handleFinish = () => {
    setActiveStep(0);
    setFormData({
      isUnitSwap: 'No',
      newDeviceId: '',
      repairComments: '',
      repairReasons: '',
      deviceStorageConnected: false,
      deviceStorageRecording: false,
      gpsMinSatellites: false,
      camerasAngles: false,
      camerasVisible: false,
      camerasRecording: false,
      camerasAudio: false,
      dsmConfigured: false,
      adasConfigured: false,
      peripherals: {
        panicButton: false,
        passengerDoorSwitch: false,
        driverDoorSwitch: false,
        cargoDoorSwitch: false,
        seatbelt: false,
        tipLock: false,
        leftIndicator: false,
        rightIndicator: false,
        securityBox: false,
      },
    });
    setFinalRefNumber('');
    onClose();
  };

  useEffect(() => {
    console.log('Selected Device:', selectedDevice);
  }, [selectedDevice]);

  //---------------------------------------
  // Render
  //---------------------------------------
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Repair Wizard
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mt: 2, mb: 1, p: 2 }}>
            {/* Step 0: "Unit Swap" radio */}
            {activeStep === 0 && (
              <RadioGroup
                name="isUnitSwap"
                value={formData.isUnitSwap}
                onChange={handleInputChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            )}

            {/* Step 1: "Add New Device" if isUnitSwap = "Yes" */}
            {activeStep === 1 && formData.isUnitSwap === 'Yes' && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Add New Device
                </Typography>
                <TextField
                  fullWidth
                  label="New Device ID"
                  name="newDeviceId"
                  value={formData.newDeviceId}
                  onChange={(e) =>
                    setFormData({ ...formData, newDeviceId: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewDevice}
                  disabled={isCreating || isCreatingSameGroup}
                >
                  {isCreating || isCreatingSameGroup ? 'Creating...' : 'Add Device'}
                </Button>
              </Box>
            )}

            {/* Step 2: Comments */}
            {activeStep === 2 && (
              <Box>
                <TextField
                  fullWidth
                  label="What is being repaired"
                  name="repairComments"
                  value={formData.repairComments}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Reasons for repair"
                  name="repairReasons"
                  value={formData.repairReasons}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                />
              </Box>
            )}

            {/* Step 3: Checklist */}
            {activeStep === 3 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Checklist
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.deviceStorageConnected}
                      onChange={handleInputChange}
                      name="deviceStorageConnected"
                    />
                  }
                  label="Device Storage Connected"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.deviceStorageRecording}
                      onChange={handleInputChange}
                      name="deviceStorageRecording"
                    />
                  }
                  label="Device Storage Recording"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.gpsMinSatellites}
                      onChange={handleInputChange}
                      name="gpsMinSatellites"
                    />
                  }
                  label="GPS Min 7 Satellites"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.camerasAngles}
                      onChange={handleInputChange}
                      name="camerasAngles"
                    />
                  }
                  label="All Camera Angles"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.camerasVisible}
                      onChange={handleInputChange}
                      name="camerasVisible"
                    />
                  }
                  label="All Cameras Clearly Visible"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.camerasRecording}
                      onChange={handleInputChange}
                      name="camerasRecording"
                    />
                  }
                  label="All Cameras Recording"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.camerasAudio}
                      onChange={handleInputChange}
                      name="camerasAudio"
                    />
                  }
                  label="All Cameras Audio Working"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.dsmConfigured}
                      onChange={handleInputChange}
                      name="dsmConfigured"
                    />
                  }
                  label="DSM Configured"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.adasConfigured}
                      onChange={handleInputChange}
                      name="adasConfigured"
                    />
                  }
                  label="ADAS Configured"
                />

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                  Peripherals
                </Typography>
                {Object.keys(formData.peripherals).map((key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={formData.peripherals[key]}
                        onChange={handleInputChange}
                        name={key}
                      />
                    }
                    label={key
                      .replace(/([A-Z])/g, ' $1')
                      .replace(/^./, (str) => str.toUpperCase())}
                  />
                ))}
              </Box>
            )}

            {/* Step 4: Summary */}
            {activeStep === 4 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Summary
                </Typography>
                <Typography variant="body1">
                  <strong>Company:</strong> {selectedCompany}
                </Typography>
                <Typography variant="body1">
                  <strong>Comments:</strong> {formData.repairComments}
                </Typography>
                <Typography variant="body1">
                  <strong>Reasons for Repair:</strong> {formData.repairReasons}
                </Typography>
                <Typography variant="body1">
                  <strong>Device Storage Connected:</strong>{' '}
                  {formData.deviceStorageConnected ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>Device Storage Recording:</strong>{' '}
                  {formData.deviceStorageRecording ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>GPS Min 7 Satellites:</strong>{' '}
                  {formData.gpsMinSatellites ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>All Camera Angles:</strong>{' '}
                  {formData.camerasAngles ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>All Cameras Clearly Visible:</strong>{' '}
                  {formData.camerasVisible ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>All Cameras Recording:</strong>{' '}
                  {formData.camerasRecording ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>All Cameras Audio Working:</strong>{' '}
                  {formData.camerasAudio ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>DSM Configured:</strong>{' '}
                  {formData.dsmConfigured ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>ADAS Configured:</strong>{' '}
                  {formData.adasConfigured ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1">
                  <strong>Peripherals:</strong>
                </Typography>
                {Object.entries(formData.peripherals).map(([key, value]) => (
                  <Typography key={key} variant="body2">
                    {key
                      .replace(/([A-Z])/g, ' $1')
                      .replace(/^./, (str) => str.toUpperCase())}
                    : {value ? 'Yes' : 'No'}
                  </Typography>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleFail}
                    disabled={isSaving}
                  >
                    {isSaving ? 'Processing...' : 'Fail'}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePass}
                    disabled={isSaving}
                  >
                    {isSaving ? 'Processing...' : 'Pass'}
                  </Button>
                </Box>
              </Box>
            )}

            {/* Step 5: Reference Number */}
            {activeStep === 5 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Reference Number
                </Typography>
                <Typography variant="body1">
                  Your reference number is: <strong>{finalRefNumber || 'N/A'}</strong>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Button variant="contained" color="primary" onClick={handleFinish}>
                    Finish
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          {/* Show Next/Back if not in final step */}
          {activeStep < 5 && activeStep !== 4 && (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>

      {/* Confirm "Unit Swap" Modal */}
      <Dialog open={confirmModalOpen} onClose={handleConfirmCancel}>
        <DialogTitle>Confirm Unit Swap</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to proceed with the unit swap?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCancel}>Cancel</Button>
          <Button onClick={handleConfirmProceed} color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default RepairWizard;
