import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: {
                    main: '#1976d2',
                    light: '#63a4ff',
                    dark: '#004ba0',
                },
                secondary: {
                    main: '#dc004e',
                    light: '#ff5983',
                    dark: '#9a0036',
                },
                background: {
                    default: '#f4f6f8',
                    paper: '#ffffff',
                },
                text: {
                    primary: '#fffff',
                    secondary: '#777777',
                },
            }
            : {
                primary: {
                    main: '#64b5f6', // Softer blue for dark mode
                    light: '#90caf9',
                    dark: '#1e88e5',
                },
                secondary: {
                    main: '#f06292', // Softer pink for dark mode
                    light: '#f48fb1',
                    dark: '#c2185b',
                },
                background: {
                    default: '#181a1b', // Slightly lighter than pure black
                    paper: '#242526', // Dark gray for better contrast
                },
                text: {
                    primary: '#e0e0e0', // Softer white for better readability
                    secondary: '#ffffff', // Subtle gray for secondary text
                },
            }),
    },
    typography: {
        fontFamily: 'Roboto Flex, Arial, sans-serif',
        h4: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            fontWeight: 500,
        },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1400,  // <--- changed from default 1200 to 1400
          xl: 1536,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                html, body, #root {
                    height: 100%;
                    margin: 0;
                    // overflow: hidden;
                }
                *::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }
                *::-webkit-scrollbar-track {
                    background: ${mode === 'light' ? '#f1f1f1' : '#333333'}; /* Adapt to theme */
                    border-radius: 8px;
                }
                *::-webkit-scrollbar-thumb {
                    background-color: ${mode === 'light' ? '#c8c8c8' : '#555555'}; /* Adapt to theme */
                    border-radius: 8px;
                }
                *::-webkit-scrollbar-thumb:hover {
                    background: ${mode === 'light' ? '#aaaaaa' : '#777777'};
                }
            `,
        },
        // MuiPaper: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: '0px', // Remove rounded corners for sharper design
        //             boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
        //         },
        //     },
        // },
        // MuiCard: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: '0px',
        //         },
        //     },
        // },
    },
});

const theme = (mode) => createTheme(getDesignTokens(mode));

export default theme;
