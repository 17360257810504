import React, { useState } from 'react';
import { Grid, Box, Typography, Paper, Button, useTheme } from '@mui/material';
import VehicleTreeView from './TreeViewComp';
import EventGrid from './EventGrid';


export default function EventManagementPage({ currentUser }) {
  const theme = useTheme();
  const [showTreeView, setShowTreeView] = useState(true);

  const toggleTreeView = () => {
    setShowTreeView((prev) => !prev);
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, mt: 0, backgroundColor: 'background.default', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2, background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,color: theme.palette.common.white, }}>
        <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
          Event Management
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Manage and monitor events efficiently.
        </Typography>
        <Button variant="contained" color="primary" onClick={toggleTreeView}>
          {showTreeView ? 'Hide Tree View' : 'Show Tree View'}
        </Button>
      </Paper>

      <Grid container spacing={4} sx={{  overflow: 'hidden' }}>
        {showTreeView && (
          <Grid item xs={12} md={5} sx={{ height: '100%' }}>
            <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom color="textPrimary">
                Clients and Vehicles
              </Typography>
              <Box sx={{ flexGrow: 1, maxHeight: 'calc(100% - 40px)', overflowY: 'auto' }}>
                <VehicleTreeView />
              </Box>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={showTreeView ? 7 : 12} sx={{ height: '100%' }}>
          <EventGrid />
        </Grid>
      </Grid>
    </Box>
  );
}
