import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CustomCard = ({ title, description, icon, onClick, sx = {} }) => {
  return (
    <Card
      sx={{
        borderRadius: 2,
        // Dark, translucent background (similar to login modal)
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: '#fff',
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.4)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 16px 40px rgba(0, 0, 0, 0.6)',
        },
        // Allow parent component's style overrides (width, height, etc.)
        ...sx,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          // Let content scroll if you use a fixed height
          overflowY: 'auto',
        }}
      >
        {icon && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 64,
              height: 64,
              mb: 2,
              // Keep a gradient highlight for the icon background
              background: 'linear-gradient(45deg, #1976d2, #42a5f5)',
              borderRadius: '50%',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease',
              '&:hover': { transform: 'scale(1.1)' },
            }}
          >
            {icon}
          </Box>
        )}

        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            mb: 1,
            fontFamily: 'Montserrat, sans-serif',
            // White text on dark background
            color: '#fff',
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            // A lighter gray for the description
            color: '#ccc',
            fontFamily: 'Roboto, sans-serif',
            lineHeight: 1.6,
            fontSize: '0.9rem',
          }}
        >
          {description}
        </Typography>
      </CardContent>

      <Box sx={{ textAlign: 'center', pb: 2 }}>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={onClick}
          sx={{
            mt: 1,
            px: 3,
            // Keep the existing blue gradient for contrast
            background: 'linear-gradient(90deg, #1976d2, #42a5f5)',
            color: '#fff',
            fontWeight: 'bold',
            borderRadius: '8px',
            '&:hover': {
              background: 'linear-gradient(90deg, #155a9c, #1e88e5)',
            },
          }}
        >
          Explore
        </Button>
      </Box>
    </Card>
  );
};

export default CustomCard;
