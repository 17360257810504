import React from 'react';
import Navbar from './NavBar';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const AdminLayout = ({ toggleMode }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Navbar toggleMode={toggleMode} />
      <Box component="main" sx={{ flexGrow: 1, p: 2, mt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;