// BureauPortalLayout.jsx
import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import BureauPortalNavbar from './BureauPortalNavbar';

export default function BureauPortalLayout({ toggleMode }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <BureauPortalNavbar toggleMode={toggleMode} />

      {/* Enough top padding so content doesn't hide behind the 64px top bar.
          The floating subnav must push content down. 
       */}
      <Box component="main" sx={{ flexGrow: 1, mt: 8, px: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
