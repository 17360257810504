import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Paper,
  Tooltip,
  Chip,
  TextField
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Link as RouterLink } from 'react-router-dom';
import Grid2 from '@mui/material/Grid2';
import {
  useFetchDeviceGroupsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation
} from '../../Features/API/apiSlice';
import AddClientWizard from './AddClientWizard';

const ManageClients = () => {
  const { data: groupsResponse, error, isLoading } = useFetchDeviceGroupsQuery();
  const [groups, setGroups] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [openEditClientDialog, setOpenEditClientDialog] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [updateClient] = useUpdateClientMutation();
  const [deleteClient] = useDeleteClientMutation();

  useEffect(() => {
    if (groupsResponse && groupsResponse.groups) {
      setGroups(groupsResponse.groups);
    }
  }, [groupsResponse]);

  const handleOpenDeleteDialog = (client) => {
    setClientToDelete(client);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setClientToDelete(null);
  };

  const handleConfirmDelete = async () => {
    await deleteClient(clientToDelete.group_id);
    handleCloseDeleteDialog();
  };

  const handleEditClient = (client) => {
    setClientToEdit(client);
    setOpenEditClientDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditClientDialog(false);
    setClientToEdit(null);
  };

  const handleSaveEdit = async () => {
    await updateClient({ group_id: clientToEdit.group_id, title: clientToEdit.title });
    handleCloseEditDialog();
  };

  // Define the columns for the DataGrid.
  // We add an extra column "View" with a button that navigates to the client details page.
  const columns = [
    { field: 'title', headerName: 'Client Name', flex: 1 },
    { 
      field: 'vehicle_count', 
      headerName: 'Vehicles', 
      flex: 1,
      renderCell: (params) => (
        <Chip label={params.value} color="primary" variant="outlined" />
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClient(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleOpenDeleteDialog(params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'view',
      headerName: 'View',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/client-details/${params.row.group_id}`}
        >
          View Details
        </Button>
      ),
    },
  ];

  const rows = groups.map((group) => ({
    id: group.group_id,
    title: group.title,
    group_id: group.group_id,
    vehicle_count: group.vehicles ? group.vehicles.length : 0,
  }));

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 7 }}>
        <Grid2 container spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Grid2 item xs={12} md={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink component={RouterLink} to="/" color="inherit">
                Home
              </MuiLink>
              <MuiLink component={RouterLink} to="/dashboard" color="inherit">
                Dashboard
              </MuiLink>
              <MuiLink component={RouterLink} to="/settings" color="inherit">
                Settings
              </MuiLink>
              <Typography color="textPrimary">Manage Clients</Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              Manage Clients
            </Typography>
          </Grid2>
          <Grid2 item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddClientDialog(true)}
              startIcon={<AddBusinessIcon />}
            >
              Add Client
            </Button>
          </Grid2>
        </Grid2>

        <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  padding: 1,
                },
              }}
            />
          )}
        </Paper>

        {/* Add Client Wizard Dialog */}
        <Dialog
          open={openAddClientDialog}
          onClose={() => setOpenAddClientDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Add Client</DialogTitle>
          <DialogContent>
            <AddClientWizard onClose={() => setOpenAddClientDialog(false)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddClientDialog(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Client Dialog */}
        <Dialog
          open={openEditClientDialog}
          onClose={handleCloseEditDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Client</DialogTitle>
          <DialogContent>
            <TextField
              label="Client Name"
              value={clientToEdit?.title || ''}
              onChange={(e) => setClientToEdit({ ...clientToEdit, title: e.target.value })}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveEdit} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete client {clientToDelete?.title}? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default ManageClients;
