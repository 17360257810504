import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ManageCard = ({ title, icon, manageLink, groupLink, manageTitle, groupTitle }) => {
  return (
    <Card
      sx={{
        width: '100%', // Let the parent define width
        maxWidth: '100%', // Make cards wider
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '20px',
        borderRadius: '16px',
        background: `linear-gradient(135deg, #1976d2, #63a4ff)`,
        color: '#fff',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 30px rgba(0,0,0,0.2)',
        },
      }}
    >
      {/* Icon */}
      <Box
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        {icon}
      </Box>

      {/* Title */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Montserrat, sans-serif',
          mb: 2,
        }}
      >
        {title}
      </Typography>

      {/* Links */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button
          component={RouterLink}
          to={manageLink}
          variant="contained"
          sx={{
            backgroundColor: '#fff',
            color: '#1976d2',
            fontWeight: 'bold',
            '&:hover': { backgroundColor: '#e3f2fd' },
          }}
        >
          {manageTitle}
        </Button>
        {groupLink && (
          <Button
            component={RouterLink}
            to={groupLink}
            variant="contained"
            sx={{
              backgroundColor: '#fff',
              color: '#1976d2',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#e3f2fd' },
            }}
          >
            {groupTitle}
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default ManageCard;
