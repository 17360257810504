// src/Components/TestLogPage.js

import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  CircularProgress,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useFetchTestsQuery } from '../../Features/API/apiSlice';

export default function TestLogPage() {
  // State to handle the selected test and modal visibility
  const [selectedTest, setSelectedTest] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch the tests using the RTK Query hook
  const { data, error, isLoading, isFetching, refetch } = useFetchTestsQuery();

  // Handle opening the modal with the selected test
  const handleViewMore = (test) => {
    const flattenedTest = {
      ...test.full_data,
      test_type: test.test_type,
      reference_number: test.reference_number,
      device_id: test.device_id,
      created_at: test.created_at,
    };
    setSelectedTest(flattenedTest);
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTest(null);
  };

  // Define the columns for the DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'test_type', headerName: 'Test Type', width: 150 },
    { field: 'reference_number', headerName: 'Reference Number', width: 180 },
    { field: 'device_id', headerName: 'Device ID', width: 120 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 180,
      // No valueFormatter needed since the backend provides the formatted date
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleViewMore(params.row)}
          >
            View More
          </Button>
        );
      },
    },
  ];

  // Transform the fetched data into rows for the DataGrid
  const rows = data
    ? data.tests.map((test, index) => ({
        id: `${test.test_type}-${test.data.id}`, // Ensure unique ID across test types
        test_type: test.test_type.replace('_', ' ').toUpperCase(),
        reference_number: test.data.reference_number,
        device_id: test.data.device_id,
        created_at: test.data.created_at,
        full_data: test.data, // Store the entire test data for the modal
      }))
    : [];

  return (
    <Box sx={{ height: '80vh', width: '100%', padding: 2 }}>
      <Paper sx={{ padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Test Log
        </Typography>

        {/* Refresh Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button variant="outlined" onClick={refetch} disabled={isFetching}>
            Refresh
          </Button>
        </Box>

        {/* Loading State */}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          // Error State
          <Typography color="error">
            Error fetching tests: {error.toString()}
          </Typography>
        ) : (
          // DataGrid with test data
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20, 50, 100]}
            disableSelectionOnClick
            autoHeight
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#f5f5f5',
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              },
            }}
          />
        )}
      </Paper>

      {/* Modal for Viewing More Details */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Test Details</Typography>
            {selectedTest && (
              <Typography variant="subtitle1">
                <strong>Reference Number:</strong> {selectedTest.reference_number || 'N/A'}
              </Typography>
            )}
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {selectedTest ? (
            <Box>
              <Typography variant="h6" gutterBottom>
                {selectedTest.test_type}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Device ID:</strong> {selectedTest.device_id || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Created At:</strong> {selectedTest.created_at || 'N/A'}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Technician:</strong> {selectedTest.technician_name || "N/A"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Branch:</strong> {selectedTest.branch_name || "N/A"}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>ODO Reading:</strong> {selectedTest.odo_reading || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Special Notes:</strong> {selectedTest.special_notes || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Status:</strong> {selectedTest.status || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>GPS Min Satellites:</strong> {selectedTest.gps_min_satellites ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>AI Config:</strong> {selectedTest.ai_config ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Device Storage Connected:</strong> {selectedTest.device_storage_connected ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Device Storage Recording:</strong> {selectedTest.device_storage_recording ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>All Camera Angles:</strong> {selectedTest.all_camera_angles ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Cameras Visible Clean:</strong> {selectedTest.cameras_visible_clean ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Cameras Recording:</strong> {selectedTest.cameras_recording ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Cameras Audio:</strong> {selectedTest.cameras_audio ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>DSM Configured:</strong> {selectedTest.dsm_configured !== null ? (selectedTest.dsm_configured ? 'Yes' : 'No') : 'Unknown'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>ADAS Configured:</strong> {selectedTest.adas_configured !== null ? (selectedTest.adas_configured ? 'Yes' : 'No') : 'Unknown'}
              </Typography>
              {/* Handle other fields similarly */}
            </Box>
          ) : (
            <Typography>No test selected.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
